import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HookReturnValue, LocationHook, Path } from 'wouter';
// eslint-disable-next-line import/no-unresolved
import useBrowserLocation from 'wouter/use-location';
import AppUnsavedChangesPopup from './AppUnsavedChangesPopup';
import { showPopup } from '../popup/AppPopup';
import { getEntryOrSoloRoute } from '../../appShellComponents/AppRouteSwitch';
import { useFeatureFlags } from '../featureFlags';

interface IUnsavedChangesOptions {
  isDirty?: boolean;
}

const unsavedChanges: { [key: string]: IUnsavedChangesOptions } = {};

export function resetUnsavedChanges(key: string) {
  if (unsavedChanges[key]) {
    delete unsavedChanges[key];
  }
}

export function useUnsavedPopup(key: string, isDirty: boolean) {
  const { t } = useTranslation();
  useEffect(() => {
    unsavedChanges[key] = { isDirty };
    const showUnsavedChangesAlert = (event: Event) => {
      if (!isDirty || !unsavedChanges[key]) {
        return undefined;
      }
      const confirmationMessage = t('Popup_ChangesNotSaved');
      // eslint-disable-next-line no-param-reassign
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      (event || window.event).returnValue = confirmationMessage;

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', showUnsavedChangesAlert);

    return () => {
      delete unsavedChanges[key];
      window.removeEventListener('beforeunload', showUnsavedChangesAlert);
    };
  }, [isDirty]);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let setLocationHookRef = (to: string, options?: { replace?: boolean }) => { };

export function setLocationTo(to: string, options?: { replace?: boolean }) {
  setLocationHookRef(to, options);
}

export function useAppUnsavedChangesLocation(): HookReturnValue<LocationHook> {
  const [location, setLocation] = useBrowserLocation();
  setLocationHookRef = setLocation;
  const { soloUi } = useFeatureFlags();

  const appSetLocation = useCallback(async (to: Path, options?: { replace?: boolean }) => {
    const anythingUnsaved = Object.values(unsavedChanges).some((p) => p.isDirty);
    const shouldNavigate = !anythingUnsaved || (await showPopup(<AppUnsavedChangesPopup />));
    if (shouldNavigate) {
      for (const key of Object.keys(unsavedChanges)) {
        delete unsavedChanges[key];
      }
      let destination = to;
      const isSystemRoute = destination.startsWith('/systems');
      if (isSystemRoute) {
        destination = getEntryOrSoloRoute(destination, soloUi);
      }

      setLocation(destination, options);
    }
  }, [setLocation]);

  return [location, appSetLocation];
}
