import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as CostsIcon } from '../../assets/icons/Homescreen icons/Entry user app icon.svg';
import { ReactComponent as TotalSystemsIcon } from '../../assets/icons/Homescreen icons/Total systems icon.svg';
import { ReactComponent as TotalDevicesIcon } from '../../assets/icons/home_solo_devices.svg';
import { ReactComponent as GrowthDecreaseIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as GrowthIncreaseIcon } from '../../assets/icons/plus.svg';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { CanNavigateToSitesPermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { useAppPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AccountActivityBarchart, { BarColor } from './components/AccountActivityBarchart';
import './Home.scss';
import './HomeSolo.overrides.scss';
import { AccountCharges, BorderColors, Size } from '../../shared/appUIFramework/components/AppAccountChargers';
import { getEstimateNextBillDate, useAccountCharges } from '../../shared/appUIFramework/appBackend/useAccountCharges';
import { getActivityGrowth, useAccountActivity } from '../../shared/appUIFramework/appBackend/useAccountActivity';
import { CurrencyCodeString } from '../../shared/appUIFramework/appBackend/useInvoices';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useTotalUsersPerCompany } from '../../shared/appUIFramework/appBackend/useEntryUsers';
import { useSitesSummary } from '../../shared/appUIFramework/appBackend/useSitesSummary';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import { getListOfYearsFromDate } from '../payment/PaymentOverview';
import { isVatRegisteredResellerCompany, useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';
import { InstallerStatusCardContentSmall } from '../admin/AdminCompanyDetails';
import { DateFormats, useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { useFormatNumber } from '../../shared/appUIFramework/formatters/formatNumber';
import { formatPercent } from '../../shared/appUIFramework/formatters/formatPercent';

export interface IEntryHomeAnalytics {
  estimatedNextBill: number;
  estimatedNextBillDate: Date;
  estimatedNextBillCurrency: CurrencyCodeString;
  estimatedNextBillGrowth: number;
  totalEntryUsers: number;
  totalEntrySites: number;
  totalEntrySitesGrowth: number;
}

export function ProductItemsColumn({
  count,
  icon,
  title,
  growth,
}: {
  count: number,
  icon: ReactNode,
  title: string,
  growth?: number
}) {
  const formatNumber = useFormatNumber();

  return (
    <div className="app-d-flex app-flex-column app-justify-content-between app-align-items-center">
      <div className="app-d-flex app-align-items-center app-h-100">{icon}</div>
      <span className="app-font-40 app-weight-600">{formatNumber(count)}</span>
      <div className="app-h-25">
        <span className="app-font-16-22 app-uppercase">{title}</span>
        {growth != null && growth !== 0 && (
          <div className="app-d-flex app-gap-4 app-align-items-center app-justify-content-center">
            {growth > 0 ? <GrowthIncreaseIcon /> : <GrowthDecreaseIcon />}
            <span className="app-font-11-15">{formatPercent(growth * 100)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function useEntryHomeAnalytics(): IEntryHomeAnalytics | undefined {
  const { charges } = useAccountCharges();
  const date = new Date();
  const { activity: sitesActivity } = useAccountActivity(date.getFullYear(), 'sites');
  const { activity: usersActivity } = useAccountActivity(date.getFullYear(), 'users');
  const { usersTotals } = useTotalUsersPerCompany();
  const { sitesSummary } = useSitesSummary({ search: '' });

  if (!charges || !sitesActivity || !usersActivity || !usersTotals || !sitesSummary) {
    return undefined;
  }

  const currentMonth = date.getMonth();

  return {
    estimatedNextBill: charges.monthlyCharge,
    estimatedNextBillCurrency: charges.currencyCode,
    estimatedNextBillGrowth: charges.growth,
    estimatedNextBillDate: getEstimateNextBillDate(),
    totalEntryUsers: usersTotals.totalUsers,
    totalEntrySites: sitesSummary.totalSites,
    totalEntrySitesGrowth: getActivityGrowth(sitesActivity, currentMonth),
  };
}

export default function HomeSolo() {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const analytics = useEntryHomeAnalytics();
  const [activityMetricType, setActivityMetricType] = useState<'sites' | 'users'>('sites');
  const activityMetricTypes = ['sites', 'users'];
  const activityMetricLabels: Record<string, ReactNode> = {
    sites: <span className="app-selected-secondary-blue">{t('Home_AccountActivity_Systems')}</span>,
    users: <span className="app-selected-secondary-orange">{t('ViewSiteUsers_Title')}</span>,
  };
  const company = useCompany();

  const [systemType, setSystemType] = useState<'entry' | 'solo'>('entry');
  const systemTypes = ['entry', 'solo'];
  const systemTypesLabels: Record<string, ReactNode> = {
    entry: <span className="app-selected-secondary-blue">{t('Solo_PaxtonEntry')}</span>,
    solo: <span className="app-selected-secondary-orange">{t('Solo_PaxtonSolo')}</span>,
  };

  const companyStartUsageYear = new Date(company?.termsAndConditionsAcceptedAt ?? new Date().toString()).getFullYear();
  const currentYear = new Date().getFullYear().toString();
  const accountActivityPerYearOptions = getListOfYearsFromDate(companyStartUsageYear);

  const [year, setYear] = useState(currentYear);
  // noinspection MagicNumberJS
  const { activity } = useAccountActivity(year, activityMetricType);

  const [, setLocation] = useLocation();
  const { hasAnyOfPermissions } = useAppPermissions();
  const goToSystems = () => {
    if (hasAnyOfPermissions(...CanNavigateToSitesPermissions)) {
      setLocation('/systems');
    }
  };

  const { data: iaCompany } = useInternalAppsCompanyDetails();
  const paxtonRewardsInfoIncluded = iaCompany && !isVatRegisteredResellerCompany(iaCompany!);

  if (!analytics || !activity || !iaCompany) {
    return (
      <>
        <AppContentToolbar>
          <span className="app-toolbar-breadcrumb">{t('Navigation_Home')}</span>
        </AppContentToolbar>
        <div className="app-content app-py-36 app-px-30">
          <AppShowLoading showLoading />
        </div>
      </>
    );
  }

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">{t('Navigation_Home')}</span>
      </AppContentToolbar>
      <div className="app-content app-py-36 app-px-30">
        <div className="app-home-top-charts">
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewNextEstimatedBill]}>
            <div className="app-home-paper app-home-paper-next-bill">
              <div className="app-home-paper-title">
                {t('TotalAccountCharges')}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="app-home-estimated-next-bill app-cursor-pointer"
                onClick={() => {
                  setLocation('/payment/overview');
                }}
              >
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: analytics.estimatedNextBill,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  circleTopKey="Home_EstimatedNextBill_AccountCharges"
                  circleBottomKey="Home_EstimatedNextBill_A_Month"
                />
              </div>
              <div className="app-home-paper-footer">
                <span className="app-home-paper-footer-text">
                  {t('Home_EstimatedNextBill_BillDate')}
                  :&nbsp;
                </span>
                <span className="app-home-paper-footer-text app-home-paper-footer-text-green">
                  {formatDate(analytics.estimatedNextBillDate, DateFormats.MM_DD_YYYY)}
                </span>
              </div>
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalDevices]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('PaxtonEntry')}
              </div>
              <div className="app-d-flex app-align-items-center app-w-100pcnt app-justify-content-space-between app-align-items-center">
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: analytics.estimatedNextBill,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  borderColor={BorderColors.Orange}
                  size={Size.Smaller}
                  circleTopKey=""
                  circleBottomKey="InDebit"
                />
                <ProductItemsColumn
                  icon={<TotalSystemsIcon />}
                  count={analytics.totalEntrySites}
                  growth={analytics.totalEntrySitesGrowth}
                  title={t('Sites')}
                />
                <ProductItemsColumn
                  icon={<CostsIcon height={70} />}
                  count={analytics.totalEntryUsers}
                  title={t('AppUsers')}
                />
              </div>
              <div className="app-home-paper-footer" />
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalSites]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('PaxtonSolo')}
              </div>
              <div className="app-d-flex app-align-items-center app-w-100pcnt app-justify-content-space-between app-align-items-center">
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: 2000.50,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  borderColor={BorderColors.Blue}
                  size={Size.Smaller}
                  circleTopKey=""
                  circleBottomKey="InDebit"
                />
                <ProductItemsColumn
                  icon={<TotalSystemsIcon />}
                  count={analytics.totalEntrySites}
                  growth={0.12}
                  title={t('SoloSystems')}
                />
                <ProductItemsColumn
                  icon={<TotalDevicesIcon height={70} />}
                  count={analytics.totalEntryUsers}
                  title={t('Devices')}
                />
              </div>
              <div className="app-home-paper-footer" />
            </div>
          </AppPermissionComponentGuard>
        </div>
        <div className="app-grid-2-cols-gap-30 app-h-100pcnt">
          <div className="app-home-paper app-home-paper-no-paddings">
            <div className="app-home-paper-activity-title app-d-flex app-justify-content-between app-align-items-center">
              <div className="app-home-paper-activity-title-text">
                {t('Home_AccountActivity')}
              </div>
              <div className="app-d-flex app-justify-content-between">
                <AppPopupMenu
                  options={systemTypes}
                  selectedOption={systemType}
                  getOptionLabel={(option) => systemTypesLabels[option]}
                  onOptionSelected={(option) => {
                    setSystemType(option);
                  }}
                  render={() => (
                    <div
                      className="app-home-paper-activity-bar-select app-mr-20 app-d-flex app-align-items-center"
                    >
                      <span className="app-mr-9 app-whitespace-no-wrap">{systemTypesLabels[systemType]}</span>
                      <ExpandIcon />
                    </div>
                  )}
                />
                <AppPopupMenu
                  options={activityMetricTypes}
                  selectedOption={activityMetricType}
                  getOptionLabel={(option) => activityMetricLabels[option]}
                  onOptionSelected={(option) => {
                    setActivityMetricType(option);
                  }}
                  render={() => (
                    <div
                      className="app-home-paper-activity-bar-select app-mr-20 app-d-flex app-align-items-center"
                    >
                      <span className="app-mr-9 app-whitespace-no-wrap">{activityMetricLabels[activityMetricType]}</span>
                      <ExpandIcon />
                    </div>
                  )}
                />
                <AppPopupMenu
                  options={accountActivityPerYearOptions}
                  getOptionLabel={(option) => (
                    <span className={activityMetricType === 'sites'
                      ? 'app-selected-secondary-blue'
                      : 'app-selected-secondary-orange'}
                    >
                      {option}
                    </span>
                  )}
                  selectedOption={year}
                  onOptionSelected={(option) => setYear(option)}
                  render={() => (
                    <div
                      className="app-home-paper-activity-bar-select app-d-flex app-align-items-center"
                    >
                      <span className="app-mr-9">{year}</span>
                      <ExpandIcon />
                    </div>
                  )}
                />
              </div>
            </div>
            <AccountActivityBarchart
              title={activityMetricType === 'sites' ? t('Home_AccountActivity_Systems') : t('ViewSiteUsers_Title')}
              barColor={activityMetricType === 'sites' ? BarColor.Blue : BarColor.Orange}
              data={activity}
            />
          </div>
          {iaCompany && paxtonRewardsInfoIncluded && (
            <div className="app-home-paper">
              <InstallerStatusCardContentSmall
                applicationStatus={iaCompany.installerLoyaltyTier}
                percent={formatPercent(iaCompany.loyaltyDiscount * 100, true)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
