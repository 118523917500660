import {
  ReactNode, RefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/Tooltip icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Text field icons/Close icon.svg';
import { useIsMobileLayout } from '../hooks/useIsMobileLayout';
import { showPopup } from '../popup/AppPopup';
import AppOutsideListener from './AppOutsideListener';
import AppPopupTooltip from './AppPopupTooltip';

/* eslint-disable no-case-declarations */
export enum AppTooltipPlacement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

const ARROW_SIZE = 10;

function getXYForTooltipArrow(bodyRect: DOMRect, triggerRect: DOMRect) {
  const isBodyAbove = bodyRect.top < triggerRect.top && bodyRect.bottom < triggerRect.bottom;
  const xForAboveOrBelow = triggerRect.left + triggerRect.width / 2 - ARROW_SIZE / 2;
  const yForLeftOrRight = triggerRect.top + triggerRect.height / 2 - ARROW_SIZE / 2;
  const arrowSizeDelta = ARROW_SIZE;

  if (isBodyAbove) {
    return { x: xForAboveOrBelow, y: bodyRect.top + bodyRect.height - arrowSizeDelta / 2 - 2 };
  }
  const isBodyBelow = bodyRect.top > triggerRect.top && bodyRect.bottom > triggerRect.bottom;
  if (isBodyBelow) {
    return { x: xForAboveOrBelow, y: bodyRect.y - arrowSizeDelta / 2 + 2 };
  }
  const isBodyLeft = bodyRect.left < triggerRect.left && bodyRect.right < triggerRect.right;
  if (isBodyLeft) {
    return { x: triggerRect.x - arrowSizeDelta - 2, y: yForLeftOrRight };
  }
  const isBodyRight = bodyRect.left > triggerRect.left && bodyRect.right > triggerRect.right;
  if (isBodyRight) {
    return { x: bodyRect.left - arrowSizeDelta / 2 + 2, y: yForLeftOrRight };
  }

  return { x: 0, y: 0 };
}

function getXYForTooltipBody(preferredPlacement: AppTooltipPlacement, bodySizes: { width: number, height: number }, triggerRect: DOMRect) {
  const {
    top,
    left,
    right,
    bottom,
    width: triggerWidth,
    height: triggerHeight,
  } = triggerRect;

  const {
    width,
    height,
  } = bodySizes;

  const arrowSizeSpacing = ARROW_SIZE / 2;
  const getXLeft = () => left - width - arrowSizeSpacing;
  const getXOriginal = () => left - 16;
  const getXRight = () => right + arrowSizeSpacing;
  const getYTop = () => top - height - arrowSizeSpacing;
  const getYOriginal = () => top - 16;
  const getYBottom = () => bottom + arrowSizeSpacing;

  let x = 0;
  switch (preferredPlacement) {
    case AppTooltipPlacement.Left:
      x = getXLeft();
      break;
    case AppTooltipPlacement.Right:
      x = getXRight();
      break;
    default:
      x = getXOriginal();
      break;
  }

  let y = 0;
  switch (preferredPlacement) {
    case AppTooltipPlacement.Top:
      y = getYTop();
      break;
    case AppTooltipPlacement.Bottom:
      y = getYBottom();
      break;
    default:
      y = getYOriginal();
      break;
  }

  const isXOutOfLeftScreenSide = (n: number) => n < 0;
  const isXOutOfRightScreenSide = (n: number) => n + width > window.innerWidth;
  const isYOutOfTopScreenSide = (n: number) => n < 0;
  const isYOutOfBottomScreenSide = (n: number) => n + height > window.innerHeight;

  // https://silentmatt.com/rectangle-intersection/
  const isBodyIntersectsTrigger = (bodyX: number, bodyY: number) => {
    const x1 = bodyX - arrowSizeSpacing;
    const x2 = bodyX + width + arrowSizeSpacing;
    const y1 = bodyY - arrowSizeSpacing;
    const y2 = bodyY + height + arrowSizeSpacing;

    const res = x1 < left + triggerWidth
      && x2 > left
      && y1 < top + triggerHeight
      && y2 > top;

    return res;
  };
  const moveYOnePxDown = (n: number) => n + 1;
  const moveYOnePxUp = (n: number) => n - 1;
  const moveXOnePxRight = (n: number) => n + 1;
  const moveXOnePxLeft = (n: number) => n - 1;

  const healCoord = (initialValue: number, isCoordInvalid: (n: number) => boolean, healAction: (n: number) => number) => {
    const maxSteps = 1000;
    let value = initialValue;
    let steps = 0;
    while (isCoordInvalid(value)) {
      value = healAction(value);
      steps += 1;
      if (steps > maxSteps) {
        // eslint-disable-next-line no-console
        console.error('Tooltip placement error: cannot find a proper position for tooltip');
        break;
      }
    }
    return value;
  };

  // if coord is valid healCoord just returns, so enough to apply all possible healing actions
  x = healCoord(x, isXOutOfLeftScreenSide, moveXOnePxRight);
  x = healCoord(x, (acc) => isBodyIntersectsTrigger(acc, y), moveXOnePxRight); // if after right move tooltip intersects trigger, move more
  x = healCoord(x, isXOutOfRightScreenSide, moveXOnePxLeft);
  x = healCoord(x, (acc) => isBodyIntersectsTrigger(acc, y), moveXOnePxLeft); // if after left move tooltip intersects trigger, move more
  y = healCoord(y, isYOutOfTopScreenSide, moveYOnePxDown);
  y = healCoord(y, (acc) => isBodyIntersectsTrigger(x, acc), moveYOnePxDown); // if after down move tooltip intersects trigger, move more
  y = healCoord(y, isYOutOfBottomScreenSide, moveYOnePxUp);
  y = healCoord(y, (acc) => isBodyIntersectsTrigger(x, acc), moveYOnePxUp); // if after up move tooltip intersects trigger, move more

  return { x, y };
}

const DEFAULT_TOOLTIP_BREAKPOINT = 768;

export default function AppTooltip({
  children,
  className,
  disabled,
  popupTooltipBody,
  preferredPlacement = AppTooltipPlacement.Right,
  tooltipTriggerElement,
  hideTooltipIcon,
  customBreakPoint,
  popupTitle,
}: {
  children: ReactNode,
  popupTooltipBody?: ReactNode,
  className?: string,
  disabled?: boolean,
  preferredPlacement?: AppTooltipPlacement,
  tooltipTriggerElement?: RefObject<HTMLDivElement> | null,
  hideTooltipIcon?: boolean,
  customBreakPoint?: number,
  popupTitle?: string;
}) {
  const [bodyXY, setBodyXY] = useState({ x: 0, y: 0 });
  const [arrowXY, setArrowXY] = useState({ x: 0, y: 0 });
  const tooltipBody = useRef<HTMLDivElement | null>(null);
  const tooltipIcon = useRef<HTMLDivElement | null>(null);
  const svgIconRef = useRef<SVGSVGElement | null>(null);
  const [shown, setShown] = useState(false);
  const [showTimeoutPassed, setShowTimeoutPassed] = useState(false);
  const breakpoint = customBreakPoint || DEFAULT_TOOLTIP_BREAKPOINT;
  const { isMobileLayout } = useIsMobileLayout(breakpoint);

  useEffect(() => {
    // using some calculations that relies on DOM elements sizes and positions, small timeout to wait for all sizes to be ready in DOM
    setTimeout(() => {
      if (isMobileLayout || !tooltipBody.current || !tooltipIcon.current || (hideTooltipIcon && !tooltipTriggerElement)) {
        return;
      }

      const triggerRect = tooltipTriggerElement?.current?.getBoundingClientRect() ?? tooltipIcon.current.getBoundingClientRect();
      const newBodyXY = getXYForTooltipBody(
        preferredPlacement,
        {
          width: tooltipBody.current.offsetWidth,
          height: tooltipBody.current.offsetHeight,
        },
        triggerRect,
      );
      tooltipBody.current.style.left = `${newBodyXY.x}px`;
      tooltipBody.current.style.top = `${newBodyXY.y}px`;
      const newArrowXY = getXYForTooltipArrow(tooltipBody.current.getBoundingClientRect(), triggerRect);
      setBodyXY(newBodyXY);
      setArrowXY(newArrowXY);
      setShowTimeoutPassed(true);
    }, 500);
  }, [tooltipBody, shown, tooltipTriggerElement]);

  const show = useCallback(() => {
    if (isMobileLayout) {
      showPopup({
        reactNode: <AppPopupTooltip title={popupTitle || ''} body={popupTooltipBody || children} />,
        disableCloseOnOverlay: true, // without popup won't be visible, because click somehow also triggered for the container
      });
    } else {
      setShown(true);
    }
  }, [isMobileLayout]);

  const hide = useCallback(() => {
    setShown(false);
    setShowTimeoutPassed(false);
  }, []);

  const [shouldBeHiddenOnMouseLeave, setShouldBeHiddenOnMouseLeave] = useState(true);
  const tryHideOnMouseLeave = useCallback(() => {
    if (shouldBeHiddenOnMouseLeave) {
      hide();
    }
  }, [shouldBeHiddenOnMouseLeave]);

  const toggleOrPreventHideOnMouseLeave = useCallback(() => {
    if (!shown) {
      show();
      setShouldBeHiddenOnMouseLeave(false);
    } else if (shown && shouldBeHiddenOnMouseLeave) {
      setShouldBeHiddenOnMouseLeave(false);
    } else {
      hide();
      setShouldBeHiddenOnMouseLeave(true);
    }
  }, [shouldBeHiddenOnMouseLeave, show, hide]);

  const hideAndResetShouldBeHiddenOnMouseLeave = useCallback(() => {
    hide();
    setShouldBeHiddenOnMouseLeave(true);
  }, [hide]);

  useEffect(() => {
    if (tooltipTriggerElement) {
      tooltipTriggerElement.current?.addEventListener('mouseover', show);
      tooltipTriggerElement.current?.addEventListener('mouseleave', tryHideOnMouseLeave);
      tooltipTriggerElement.current?.addEventListener('click', toggleOrPreventHideOnMouseLeave);
      return () => {
        tooltipTriggerElement.current?.removeEventListener('mouseover', show);
        tooltipTriggerElement.current?.removeEventListener('mouseleave', tryHideOnMouseLeave);
        tooltipTriggerElement.current?.removeEventListener('click', toggleOrPreventHideOnMouseLeave);
      };
    }
    return () => {
    };
  }, [tooltipTriggerElement, show, tryHideOnMouseLeave, toggleOrPreventHideOnMouseLeave]);

  return (
    <AppOutsideListener
      exceptionRefs={[tooltipIcon, tooltipTriggerElement, tooltipBody, svgIconRef]}
      onClick={hideAndResetShouldBeHiddenOnMouseLeave}
    >
      <div
        ref={tooltipIcon}
        className={`app-tooltip ${className || ''} ${shown ? 'app-tooltip-shown' : ''}`}
      >
        {!hideTooltipIcon && (
          <TooltipIcon
            ref={svgIconRef}
            onClick={disabled ? undefined : toggleOrPreventHideOnMouseLeave}
            onMouseOver={disabled ? undefined : show}
            onMouseLeave={disabled ? undefined : tryHideOnMouseLeave}
          />
        )}
        {shown && createPortal(
          <>
            <div
              ref={tooltipBody}
              className="app-tooltip-body"
              style={{
                position: 'absolute',
                left: `${bodyXY.x}px`,
                top: `${bodyXY.y}px`,
                visibility: showTimeoutPassed ? 'visible' : 'hidden',
              }}
            >
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                onClick={hide}
                className="app-tooltip-cross app-cursor-pointer"
              >
                <CloseIcon />
              </div>
              {children}
            </div>
            <div
              className="app-tooltip-arrow"
              style={{
                left: `${arrowXY.x}px`,
                top: `${arrowXY.y}px`,
                position: 'absolute',
                color: 'white',
                backgroundColor: 'white',
                visibility: showTimeoutPassed ? 'visible' : 'hidden',
                width: ARROW_SIZE,
                height: ARROW_SIZE,
                transform: 'rotate(45deg)',
              }}
            />
          </>, document.body,
        )}
      </div>
    </AppOutsideListener>
  );
}
