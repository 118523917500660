import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as BackwardBreadCrumpIcon } from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import SystemFormSolo from './components/SystemFormSolo';

export default function SystemsNewSolo() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const goToSystemsPage = () => setLocation('/systems/solo');

  return (
    <>
      <AppContentToolbar>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <span className="app-toolbar-breadcrumb-separator app-hover-pointer" onClick={goToSystemsPage}><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{t('Solo_PaxtonSolo')}</span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb app-hover-pointer">{t('SystemsAdd')}</span>
      </AppContentToolbar>
      <div className="app-content">
        <SystemFormSolo goBack={goToSystemsPage} />
      </div>
    </>
  );
}
