/* eslint-disable max-classes-per-file */
import {
  Redirect,
  Route,
  Switch,
  useRoute,
} from 'wouter';
import { ReactNode } from 'react';
import { AppPermissionPageGuard } from '../appUIFramework/appPermisions/AppPermisionGuard';
import {
  CanNavigateToAdminsPermissions,
  CanNavigateToCompanyDetailsPermissions,
  CanNavigateToCreateAdminPermissions,
  CanNavigateToCreateEntryUserPermissions,
  CanNavigateToCreateSitePermissions,
  CanNavigateToEditAdminPermissions,
  CanNavigateToEditEntryUserPermissions,
  CanNavigateToEntryUsersPermissions,
  CanNavigateToHardwarePermissions,
  CanNavigateToHomePermissions,
  CanNavigateToMakePaymentPermissions,
  CanNavigateToPaymentOverviewPermissions,
  CanNavigateToPaymentPermissions,
  CanNavigateToSiteCostsPermissions,
  CanNavigateToSiteDetailsPermissions,
  CanNavigateToSitePermissions,
  CanNavigateToSitesPermissions,
  CanViewAdminDocuments,
} from '../appUIFramework/appPermisions/permissionsGroups';
import Home from '../../routes/home/Home';
import Profile from '../../routes/profile/Profile';
import { AccountStatusUIState, useAccountInfo } from '../appUIFramework/appBackend/useAccountInfo';
import Systems from '../../routes/systems/Systems';
import SystemsNew from '../../routes/systems/SystemsNew';
import System from '../../routes/systems/System';
import SystemTabsEntryUsers from '../../routes/systems/SystemTabsEntryUsers';
import SystemEntryUsersNew from '../../routes/systems/SystemEntryUsersNew';
import SystemEntryUser from '../../routes/systems/SystemEntryUser';
import SystemTabsHardware from '../../routes/systems/SystemTabsHardware';
import SystemTabsSystemDetails from '../../routes/systems/SystemTabsSystemDetails';
import PaymentOverview from '../../routes/payment/PaymentOverview';
import PaymentInvoicesPdf from '../../routes/payment/PaymenInvoicesPdf';
import PaymentPayments from '../../routes/payment/PaymentPayments';
import AdminPeople from '../../routes/admin/AdminPeople';
import AdminPeopleCreate from '../../routes/admin/AdminPeopleCreate';
import AdminPeopleDetails from '../../routes/admin/AdminPeopleDetails';
import AdminCompanyDetails from '../../routes/admin/AdminCompanyDetails';
import PageNotFound from '../../routes/PageNotFound';
import { useAppNavigationLinks } from './AppNavigationMenu';
import SystemTabsCosts from '../../routes/systems/SystemTabsCosts';
import SystemTabsCostsSolo from '../../routes/systems/SystemTabsCostsSolo';
import PaymentTermsAndConditions from '../../routes/payment/PaymentTermsAndConditions';
import AdminDocuments from '../../routes/admin/AdminDocuments';
import { isTaxMethodUsedInCompany, useInternalAppsCompanyDetails } from '../appUIFramework/appBackend/useKeyContact';
import { VatEvidenceType } from '../appUIFramework/constants/Countries';
import AppErrorPage from '../../routes/AppErrorPage';
import { useFeatureFlags } from '../appUIFramework/featureFlags';
import HomeSolo from '../../routes/home/HomeSolo';
import SystemsSolo from '../../routes/systems/SystemsSolo';
import SystemTabsEntryUsersSolo from '../../routes/systems/SystemTabsEntryUsersSolo';
import SystemEntryUserSolo from '../../routes/systems/SystemEntryUserSolo';
import SystemTabsHardwareSolo from '../../routes/systems/SystemTabsHardwareSolo';
import SystemTabsSystemDetailsSolo from '../../routes/systems/SystemTabsSystemDetailsSolo';
import SystemSolo from '../../routes/systems/SystemSolo';
import PaymentOverviewSolo from '../../routes/payment/PaymentOverviewSolo';
import PaymentInvoicesSolo from '../../routes/payment/PaymentInvoicesSolo';
import PaymentPaymentsSolo from '../../routes/payment/PaymentPaymentsSolo';
import SystemsNewSolo from '../../routes/systems/SystemsNewSolo';

export function isNeedReplacementForSystems(url: string) {
  const isNeedReplacement = url.startsWith('/systems')
    && !url.startsWith('/systems/entry')
    && !url.startsWith('/systems/solo');

  return isNeedReplacement;
}

export function getEntryOrSoloRoute(url: string, soloUi: boolean, solo?: boolean) {
  if (!url.startsWith('/systems')) {
    throw new Error('not a system url');
  }

  const res = soloUi && isNeedReplacementForSystems(url)
    ? url.replace('/systems', `/systems/${solo ? 'solo' : 'entry'}`)
    : url;

  return res;
}

export function useEntryOrSoloSystemUrl(url: string): string {
  const { soloUi } = useFeatureFlags();
  return getEntryOrSoloRoute(url, soloUi);
}

export function useEntryOrSoloSystemRoute(url: string, solo?: boolean) {
  const { soloUi } = useFeatureFlags();
  const actualUrl = getEntryOrSoloRoute(url, soloUi, solo);
  const route = useRoute(actualUrl);

  return route;
}

function AppSoloRoute({ path, children }: { path: string, children: ReactNode }) {
  const { soloUi } = useFeatureFlags();
  return (
    <Route path={path}>
      {soloUi
        ? (
          <>
            {children}
          </>
        )
        : <PageNotFound />}
    </Route>
  );
}

function AccountStatusGuard({ accountStatuses, children }: { accountStatuses: AccountStatusUIState[], children: ReactNode }) {
  const { accountInfo } = useAccountInfo();
  const isCurrentStatusAllowed = accountStatuses.includes(accountInfo?.accountStatus ?? AccountStatusUIState.Ok);
  return (isCurrentStatusAllowed
    ? <>{children}</>
    : <Redirect to="/" />);
}

export default function AppRouteSwitch() {
  const appNavigationLinks = useAppNavigationLinks();
  const { soloUi } = useFeatureFlags();
  const homePageRoute = appNavigationLinks.find((p) => !p.disabled)!.to;
  if (!homePageRoute) {
    throw new Error('home page route has to be defined all the time');
  }

  const { data: internalAppsCompany } = useInternalAppsCompanyDetails();

  return (
    <Switch>
      <Route path="/">
        <Redirect to={homePageRoute} />
      </Route>
      <Route path="/home">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToHomePermissions}>
            {soloUi ? <HomeSolo /> : <Home />}
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path={getEntryOrSoloRoute('/systems', soloUi, false)}>
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToSitesPermissions}>
            <Systems />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/0', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateSitePermissions}>
            <SystemsNew />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSitePermissions}>
            <System />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <AppSoloRoute path="/systems/solo">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToSitesPermissions}>
            <SystemsSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/0">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateSitePermissions}>
            <SystemsNewSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:siteId/users">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEntryUsersPermissions}>
            <SystemTabsEntryUsersSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:siteId/users/:userId">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEntryUsersPermissions}>
            <SystemEntryUserSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:siteId/hardware">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEntryUsersPermissions}>
            <SystemTabsHardwareSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:siteId/costs">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteCostsPermissions}>
            <SystemTabsCostsSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:siteId/system-details">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteDetailsPermissions}>
            <SystemTabsSystemDetailsSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/systems/solo/:systemId">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSitePermissions}>
            <SystemSolo />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </AppSoloRoute>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/costs', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteCostsPermissions}>
            <SystemTabsCosts />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/entry-users', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEntryUsersPermissions}>
            <SystemTabsEntryUsers />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/entry-users/0', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateEntryUserPermissions}>
            <SystemEntryUsersNew />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/entry-users/:entryUserId', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEditEntryUserPermissions}>
            <SystemEntryUser />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/hardware', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToHardwarePermissions}>
            <SystemTabsHardware />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path={getEntryOrSoloRoute('/systems/:siteId/system-details', soloUi, false)}>
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteDetailsPermissions}>
            <SystemTabsSystemDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/payment">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentPermissions}>
          {soloUi ? <PageNotFound /> : <PaymentOverview />}
        </AppPermissionPageGuard>
      </Route>
      <AppSoloRoute path="/payment/solo/overview">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentOverviewPermissions}>
          <PaymentOverviewSolo />
        </AppPermissionPageGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/payment/solo/invoices">
        <AppPermissionPageGuard permissions={CanNavigateToMakePaymentPermissions}>
          <PaymentInvoicesSolo />
        </AppPermissionPageGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/payment/solo/payments">
        <AppPermissionPageGuard permissions={CanNavigateToMakePaymentPermissions}>
          <PaymentPaymentsSolo />
        </AppPermissionPageGuard>
      </AppSoloRoute>
      <AppSoloRoute path="/payment/solo/terms">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentPermissions}>
          <PaymentTermsAndConditions />
        </AppPermissionPageGuard>
      </AppSoloRoute>
      <Route path="/payment/overview">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentOverviewPermissions}>
          {soloUi ? <PageNotFound /> : <PaymentOverview />}
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/overview/:invoiceId">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentOverviewPermissions}>
          <PaymentInvoicesPdf />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/payments">
        <AppPermissionPageGuard permissions={CanNavigateToMakePaymentPermissions}>
          {soloUi ? <PageNotFound /> : <PaymentPayments />}
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/terms">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentPermissions}>
          {soloUi ? <PageNotFound /> : <PaymentTermsAndConditions />}
        </AppPermissionPageGuard>
      </Route>
      <Route path="/admin">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToAdminsPermissions}>
            <AdminPeople />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToAdminsPermissions}>
            <AdminPeople />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people/0">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateAdminPermissions}>
            <AdminPeopleCreate />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people/:id">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEditAdminPermissions}>
            <AdminPeopleDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/company-details">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToCompanyDetailsPermissions}>
            <AdminCompanyDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      {!!internalAppsCompany && isTaxMethodUsedInCompany(internalAppsCompany, VatEvidenceType.TaxCertificate) ? (
        <Route path="/admin/documents">
          <AppPermissionPageGuard permissions={[...CanViewAdminDocuments]}>
            <AdminDocuments />
          </AppPermissionPageGuard>
        </Route>
      ) : <></>}
      <Route path="/error/:code">
        <AppErrorPage />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
