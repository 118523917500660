import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import {
  useEntryUsersTotals,
} from '../../shared/appUIFramework/appBackend/useEntryUsers';
import {
  CanNavigateToEditEntryUserPermissions,
} from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { RegistrationStatus } from '../../shared/appUIFramework/constants/RegistrationStatus';
import { SortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import SystemTabsHeader from './components/SystemTabsHeader';
import { SortNumericOptions } from '../../shared/appUIFramework/hooks/useSortNumericOptions';
import { Urls } from '../../shared/backend/urls';
import { IEntryUserGridItem, IEntryUsersTotals } from './models/IUserData';
import AppTablePage from '../../shared/appUIFramework/components/AppTablePage';
import { hasAnyOfPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { IGridQueryParams } from './models/IGridQueryParams';
import './SystemTabsEntryUsers.scss';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';

interface IUsersGridQueryParams extends IGridQueryParams {
  nameSort?: SortStringOptions;
  isActive?: boolean;
  registrationStatus?: RegistrationStatus;
  monitorSort?: SortNumericOptions;
  permissionsFilter?: string;
}

export default function SystemTabsEntryUsersSolo() {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/solo/:siteId/:tab');
  const [, setLocation] = useLocation();
  const goToEntryUserDetails = (itemId: string) => {
    setLocation(`/systems/solo/${params?.siteId}/users/${itemId}`);
  };

  return (
    <>
      <SystemTabsHeader solo activeTab="entry-users" />
      <AppTablePage<IEntryUserGridItem, IUsersGridQueryParams, IEntryUsersTotals>
        inTabsShell
        searchText={t('Sites_SearchEntryUsers')}
        table={{
          query: {
            itemsUrl: Urls.UsersWithSiteId(params?.siteId),
            initialQueryParams: {
              search: '',
              nameSort: SortStringOptions.Ascending.toString() as any,
            },
            useTotals: (queryParams: IUsersGridQueryParams) => {
              const { usersTotals: totals, mutate } = useEntryUsersTotals(params?.siteId, {
                nameSort: queryParams.nameSort,
                isActive: queryParams.isActive,
                registrationStatus: queryParams.registrationStatus,
              });

              const reduceTotals = (item: IEntryUserGridItem) => mutate({
                totalUsers: totals!.totalUsers - 1,
                totalActiveUsers: item.isActive
                  ? totals!.totalActiveUsers - 1
                  : totals!.totalActiveUsers,
              }, { revalidate: false });

              return {
                totals,
                totalCount: totals?.totalUsers || 0,
                reduceTotals,
                getTotalValues: ({ totalUsers }) => ([
                  {
                    label: t('Info_TotalUsers'),
                    value: totalUsers,
                  },
                ]),
              };
            },
          },
          goToItem: (item) => hasAnyOfPermissions(...CanNavigateToEditEntryUserPermissions) && goToEntryUserDetails(item.id),
          customColumnsClass: {
            desktop: 'app-table-system-tabs-solo-users',
          },
          columns: [
            {
              title: t('Table_Name'),
              queryParamSetting: {
                key: 'nameSort',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ') as string],
                  [SortStringOptions.Descending, t('TableSort_ZTOA') as string],
                ],
                type: 'sort',
              },
              getValue: (item: IEntryUserGridItem) => item.name,
            },
            {
              title: t('Table_Email'),
              getValue: (item: IEntryUserGridItem) => item.emailAddress,
            },
            {
              title: t('Solo_AccessLevel'),
              getValue: (item: IEntryUserGridItem) => item.emailAddress,
            },
            {
              title: t('Solo_AppPermissions'),
              getValue: (item: IEntryUserGridItem) => item.emailAddress,
              queryParamSetting: {
                key: 'permissionsFilter',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ') as string],
                  [SortStringOptions.Descending, t('TableSort_ZTOA') as string],
                ],
                type: 'filter',
              },
            },
          ],
        }}
      />
    </>
  );
}
