import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import { useEntryUser } from '../../shared/appUIFramework/appBackend/useEntryUser';
import { useSiteInfo } from '../../shared/appUIFramework/appBackend/useSiteInfo';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import EntryUserForm from './components/EntryUserForm';
import { AccountStatusUIState, useAccountInfo } from '../../shared/appUIFramework/appBackend/useAccountInfo';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';

export default function SystemEntryUser() {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/:siteId/entry-users/:entryUserId');
  const [, setLocation] = useLocation();
  const siteId = params?.siteId;
  const entryUserId = params?.entryUserId === '0' ? '' : params?.entryUserId;
  const { entryUser } = useEntryUser(siteId, entryUserId);
  const { siteInfo } = useSiteInfo(siteId);
  const goToSite = () => {
    if (siteId) {
      setLocation(`/systems/${siteId}/entry-users`);
    }
  };
  const { accountInfo: { accountStatus } } = useAccountInfo();

  return (
    <>
      <AppContentToolbar>
        <AppShowLoading inline showLoading={siteInfo == null || entryUser == null}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <span className="app-toolbar-breadcrumb-separator app-hover-pointer" onClick={goToSite}><BackwardBreadCrumpIcon /></span>
          <span className="app-toolbar-breadcrumb">{t('Solo_PaxtonEntry')}</span>
          <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <span className="app-toolbar-breadcrumb app-hover-pointer" onClick={goToSite}>{ siteInfo?.siteName }</span>
          <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
          <span className="app-toolbar-breadcrumb">{ `${entryUser?.firstName} ${entryUser?.lastName}` }</span>
        </AppShowLoading>
      </AppContentToolbar>
      <EntryUserForm disabled={accountStatus === AccountStatusUIState.Limited} />
    </>
  );
}
