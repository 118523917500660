import { httpGet, httpPostJson, httpPostUrlFormEncoded } from '../../shared/backend/http/http';
import { Urls } from '../../shared/backend/urls';
import { ISignUpUserDto } from './signup.models';
import { NotSuccessResponseError } from '../../shared/backend/errors/notSuccessResponseError';
import { StatusCodesEnum } from '../../shared/appUIFramework/constants/StatusCodesEnum';

export async function signup(user: ISignUpUserDto): Promise<void> {
  const result = await httpPostUrlFormEncoded(Urls.InternalAppsSignUp, user, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    unauthorized: true,
  });

  const resultBody = await result.json();

  if (resultBody && resultBody.ErrorId && resultBody.ErrorMessage) {
    throw new NotSuccessResponseError(resultBody.ErrorMessage);
  }
}

export async function isEmailWhitelisted(email?: string): Promise<boolean> {
  const response = await httpGet(Urls.IsWhiteListed(email || ''), {
    unauthorized: true,
  });
  const responseText = await response.text();
  return responseText === 'True';
}

export async function isTaxCodeValid(taxCode: string, customerReference: string): Promise<string> {
  try {
    const res = await httpPostJson<{Example: string}>(Urls.InternalAppsValidateTaxCode, {
      customerReference,
      taxCode,
    });

    return res.Example;
  } catch (e) {
    if (e instanceof NotSuccessResponseError) {
      if (e.response.status === StatusCodesEnum.BadRequest) {
        const res = await e.response.json();
        return res.Example;
      }

      throw e;
    }
  }

  return '';
}
