import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';
import SystemFormSolo from './components/SystemFormSolo';

export default function SystemSolo() {
  const { t } = useTranslation();

  const [, params] = useEntryOrSoloSystemRoute('/systems/solo/:siteId');
  const systemId = params?.systemId;
  const [, setLocation] = useLocation();
  const goToSystemsSystemDetailsPage = () => setLocation(`/systems/solo/${systemId}/system-details`);

  return (
    <>
      <AppContentToolbar>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <span className="app-toolbar-breadcrumb-separator app-hover-pointer" onClick={goToSystemsSystemDetailsPage}><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{t('Solo_PaxtonSolo')}</span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{t('Solo_EditSystemDetails')}</span>
      </AppContentToolbar>
      <div className="app-content">
        <SystemFormSolo systemId={systemId} goBack={goToSystemsSystemDetailsPage} />
      </div>
    </>
  );
}
