import MasterCard from '../../../assets/stripe icons/master_card.png';
import Visa from '../../../assets/stripe icons/visa.png';
import AmericanExpress from '../../../assets/stripe icons/american_express.png';
import Discover from '../../../assets/stripe icons/discover.png';
import DinnersClub from '../../../assets/stripe icons/dinners_club.png';
import JCB from '../../../assets/stripe icons/jcb.png';
import ChinaUnionPay from '../../../assets/stripe icons/china_union_pay.png';
import './StripeIcons.scss';

export enum StripeIconsSize {
  Normal,
  Small
}

export default function StripeIcons({ size }: { size?: StripeIconsSize }) {
  return (
    <div className="app-d-flex app-w-100pcnt app-align-items-center app-justify-content-center">
      <div className={`app-stripe-icons app-d-flex app-gap-8 ${size === StripeIconsSize.Small ? 'app-stripe-icons-small' : ''}`}>
        <img src={MasterCard} alt="MasterCard" />
        <img src={Visa} alt="Visa" />
        <img src={AmericanExpress} alt="AmericanExpress" />
        <img src={Discover} alt="Discover" />
        <img src={DinnersClub} alt="DinnersClub" />
        <img src={JCB} alt="JCB" />
        <img src={ChinaUnionPay} alt="ChinaUnionPay" />
      </div>
    </div>
  );
}
