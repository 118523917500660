import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useInternalAppsCompanyDetails } from '../../../shared/appUIFramework/appBackend/useKeyContact';
import {
  CountryCode,
  getCountryCodeByCountryId,
  getCountryName,
  isCountryCode,
  listOfCountryNamesForDropdown,
  useDefaultCountryCode,
} from '../../../shared/appUIFramework/constants/Countries';
import { ISiteInfo, ISiteInfoPartial } from '../models/ISiteInfo';
import { useAppForm } from '../../../shared/appUIFramework/appBackend/useAppForm';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import { isSiteNameUnique, updateSite } from '../../../shared/appUIFramework/appBackend/useSites';
import { useUnsavedPopup } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import {
  ADDRESS_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NO_SPECIAL_CHARACTERS_PATTERN,
  POSTCODE_MAX_LENGTH,
} from '../../../shared/appUIFramework/constants/constants';
import { appSubmitAndHandleErrors } from '../../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import { ReactComponent as AddressIcon } from '../../../assets/icons/Text field icons/Address icon.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/Text field icons/Company name icon.svg';
import { ReactComponent as CountryIcon } from '../../../assets/icons/Text field icons/Country Icon.svg';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';

export default function SystemFormSolo({ systemId = '', goBack }: { systemId?: string, goBack: () => void }) {
  const { t } = useTranslation();

  const { data: iaCompany } = useInternalAppsCompanyDetails();
  const defaultCountryCode = useDefaultCountryCode(getCountryCodeByCountryId(iaCompany?.address.countryId));
  const { siteInfo, mutate: mutateSite } = useSiteInfo(systemId);

  const getDefaultValue = () => ({
    siteAddress: {
      countryCode: defaultCountryCode,
    },
  } as ISiteInfoPartial);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getFieldState,
    formState: {
      errors, isSubmitting, isDirty, isValid, isValidating,
    },
    getValues,
  } = useAppForm<ISiteInfo>(siteInfo, {
    mode: 'onChange',
    defaultValues: getDefaultValue(),
  });

  const company = useCompany();
  const saveSite: (formData: ISiteInfo) => Promise<void> = async (formData: ISiteInfo) => {
    await updateSite(formData, company?.id);
    await mutateSite!(formData);
  };

  useUnsavedPopup('systems-solo', isDirty);

  const siteInfoLoadInProgress = !!systemId && !siteInfo;
  const defaultValueLoadInProgress = !systemId && defaultCountryCode == null;
  const asyncOperationInProgress = siteInfoLoadInProgress || defaultValueLoadInProgress || isSubmitting;

  return (
    <AppShowLoading showLoading={asyncOperationInProgress}>
      <form
        className="app-d-flex-vertical-100 app-form"
        onSubmit={appSubmitAndHandleErrors({
          submit: saveSite, handleSubmit, reset, goBack, mutate: mutateSite,
        })}
      >
        <div className="app-d-flex app-flex-vertical app-mobile-form app-form-container app-flex-1">
          <div className="app-form-column app-form-column-left">
            <div className="app-form-section-title system-form-title">{t('SiteAdd_SiteDetails')}</div>
            <div
              className="app-form-control"
              aria-invalid={!!errors.siteName && getFieldState('siteName').isTouched}
            >
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                <span>{t('SiteAdd_SiteName')}</span>
                <span className="app-text-secondary-red-color">&nbsp;*</span>
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <CompanyIcon />
                </div>
                <input
                  {...register('siteName', {
                    required: true,
                    maxLength: NAME_MAX_LENGTH,
                    pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                    validate: {
                      unique: async (value: string) => {
                        if (!value || !company?.id) {
                          return true;
                        }
                        const { id } = getValues();

                        const isUnique = await isSiteNameUnique(value, company!.id, id);
                        const { siteName } = getValues();
                        if (!siteName || !siteName.trim()) {
                          return t('SiteAdd_SiteNameRequired') as string;
                        }

                        if (!isUnique) {
                          return t('SiteNameShouldBeUnique') as string;
                        }

                        return true;
                      },
                    },
                  })
                  }
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteName && getFieldState('siteName').isTouched && (
                <span className="app-form-error app-form-error-with-prefix">
                  {errors.siteName?.type === 'required' && t('SiteAdd_SiteNameRequired')}
                  {errors.siteName?.type === 'unique' && t('SiteNameShouldBeUnique')}
                  {errors.siteName?.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                  {errors.siteName?.type === 'maxLength' && t(
                    'InputMaxLengthErrorMessage',
                    { maxLength: NAME_MAX_LENGTH },
                  )}
                </span>
              )}
            </div>
            <div className="app-form-control">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_Address1')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <AddressIcon />
                </div>
                <input
                  {...register('siteAddress.address1', {
                    maxLength: ADDRESS_MAX_LENGTH,
                  })}
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteAddress?.address1?.type === 'maxLength'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputMaxLengthErrorMessage',
                      { maxLength: ADDRESS_MAX_LENGTH },
                    )}
                  </span>
                )}
            </div>
            <div className="app-form-control">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_Address2')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <AddressIcon />
                </div>
                <input
                  {...register('siteAddress.address2', {
                    maxLength: ADDRESS_MAX_LENGTH,
                  })}
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteAddress?.address2?.type === 'maxLength'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputMaxLengthErrorMessage',
                      { maxLength: ADDRESS_MAX_LENGTH },
                    )}
                  </span>
                )}
            </div>
            <div className="app-form-control">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_City')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <AddressIcon />
                </div>
                <input
                  {...register('siteAddress.city', {
                    maxLength: ADDRESS_MAX_LENGTH,
                    pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                  })}
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteAddress?.city?.type === 'pattern'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputInvalidCharactersErrorMessage',
                    )}
                  </span>
                )}
              {errors.siteAddress?.city?.type === 'maxLength'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputMaxLengthErrorMessage',
                      { maxLength: ADDRESS_MAX_LENGTH },
                    )}
                  </span>
                )}
            </div>
            <div className="app-form-control">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_State')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <AddressIcon />
                </div>
                <input
                  {...register('siteAddress.state', {
                    maxLength: ADDRESS_MAX_LENGTH,
                  })}
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteAddress?.state?.type === 'maxLength'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputMaxLengthErrorMessage',
                      { maxLength: ADDRESS_MAX_LENGTH },
                    )}
                  </span>
                )}
            </div>
            <div className="app-form-control">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_Postcode')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <AddressIcon />
                </div>
                <input
                  {...register('siteAddress.postCode', {
                    maxLength: POSTCODE_MAX_LENGTH,
                    pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                  })}
                  type="text"
                  className="app-form-input"
                />
              </div>
              {errors.siteAddress?.postCode?.type === 'pattern'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputInvalidCharactersErrorMessage',
                    )}
                  </span>
                )}
              {errors.siteAddress?.postCode?.type === 'maxLength'
                && (
                  <span className="app-form-error app-form-error-with-prefix">
                    {t(
                      'InputMaxLengthErrorMessage',
                      { maxLength: POSTCODE_MAX_LENGTH },
                    )}
                  </span>
                )}
            </div>
            <div className="app-form-control app-form-control-country">
              <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                {t('SiteAdd_Country')}
              </div>
              <div className="app-form-control-input">
                <div className="app-form-control-prefix">
                  <CountryIcon />
                </div>
                <Controller
                  control={control}
                  name="siteAddress.countryCode"
                  render={({ field: { onChange, value } }) => (
                    <AppSelect
                      className="app-form-input app-select-not-in-view"
                      incomingValue={getCountryName(value as CountryCode)}
                      options={listOfCountryNamesForDropdown()}
                      getOptionPrefix={(countryCode) => {
                        const country = countryCode || getValues('siteAddress.countryCode');
                        return (!!country && isCountryCode(country) && (
                          <>
                            <ReactCountryFlag
                              countryCode={country || ''}
                              svg
                            />
                          </>
                        ));
                      }}
                      onOptionSelected={(countryName) => {
                        onChange(countryName.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="app-d-flex app-justify-content-end app-form-bottom-action-bar app-mt-28">
          <button
            onClick={goBack}
            className="app-secondary-button app-mr-9"
            type="button"
          >
            {t('Form_Cancel')}
          </button>
          <button
            disabled={!isValid || isSubmitting || isValidating}
            className="app-primary-button"
            type="submit"
          >
            {t('Form_Save')}
          </button>
        </div>
      </form>
    </AppShowLoading>
  );
}
