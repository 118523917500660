import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import {
  getInvoice,
  IInvoice,
  IInvoiceFilter,
  InvoicePaymentMethodFilterOptions,
  InvoiceStatus,
  InvoiceStatusFilterOptions,
  useInvoicePaymentMethodOptions,
  useInvoiceStatusOptions,
  useInvoicesTotalNumber,
} from '../../shared/appUIFramework/appBackend/useInvoices';
import { useFetchAndDownloadInvoice } from './invoice.helper';
import { CanNavigateToMakePaymentPermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { hasAnyOfPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import AppTablePage from '../../shared/appUIFramework/components/AppTablePage';
import { DateFormats, useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { useClaims } from '../../shared/backend/auth/claims';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import { Urls } from '../../shared/backend/urls';
import { IGridQueryParams } from '../systems/models/IGridQueryParams';
import PaymentHeaderSolo from './components/PaymentHeaderSolo';
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download icon.svg';
import './PaymentInvoicesSolo.scss';
import { handleHttpRequestError } from '../../shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';

type InvoiceType = 'solo' | 'entry';

type InvoiceQueryParams = IInvoiceFilter & IGridQueryParams & { invoiceType?: InvoiceType };

type InvoiceTotals = { totals: number }

export function getInvoiceStatusColorClassName(status: InvoiceStatus) {
  return {
    [InvoiceStatus.Unknown]: '',
    [InvoiceStatus.Unpaid]: 'app-color-secondary-yellow',
    [InvoiceStatus.Pending]: 'app-color-secondary-blue',
    [InvoiceStatus.Paid]: 'app-color-primary-green',
    [InvoiceStatus.Failed]: 'app-color-secondary-red',
    [InvoiceStatus.Overdue]: 'app-color-secondary-red',
  }[status];
}

function InvoiceStatusColumn({ item }: { item: IInvoice }) {
  const { getOptionLabel: getInvoiceStatusLabel } = useInvoiceStatusOptions();
  const classes = 'app-uppercase app-d-flex app-justify-content-space-between app-weight-600';
  const { customerReference } = useClaims();

  const fetchAndDownloadInvoice = useFetchAndDownloadInvoice(async (invoiceId) => {
    try {
      return getInvoice(customerReference, invoiceId);
    } catch (error) {
      await handleHttpRequestError(error);
      return null;
    }
  });

  const downloadPdf = async (invoiceId: number, e: MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    await fetchAndDownloadInvoice(invoiceId);
  };

  return (
    <div className={classes}>
      <div className={`app-payment-overview-status-text ${getInvoiceStatusColorClassName(item.invoiceStatusId)}`}>{getInvoiceStatusLabel(item.invoiceStatusId)}</div>
      <div><DownloadIcon onClick={(e) => downloadPdf(+item.id, e)} /></div>
    </div>
  );
}

export default function PaymentInvoicesSolo() {
  const { t } = useTranslation();
  const { customerReference } = useClaims();
  const [, setLocation] = useLocation();

  const goToInvoice = (invoiceId: number) => {
    setLocation(`/payment/overview/${invoiceId}`);
  };

  const formatDate = useFormatDate();
  const { getOptionLabel: getPaymentMethodLabel } = useInvoicePaymentMethodOptions();

  return (
    <>
      <PaymentHeaderSolo activeTab="invoices" />
      <AppTablePage<IInvoice, InvoiceQueryParams, InvoiceTotals>
        inTabsShell
        searchText={t('Solo_SearchInvoices')}
        table={{
          query: {
            itemsUrl: getUrlWithQueryParams(Urls.Invoices(customerReference)),
            initialQueryParams: {
              search: '',
            },
            useTotals: (queryParams: InvoiceQueryParams) => {
              const invoicesTotalNumber = useInvoicesTotalNumber({
                paidByMethod: queryParams.paidByMethod,
                invoiceStatusIds: queryParams.invoiceStatusIds,
              });

              return {
                totals: invoicesTotalNumber != null ? { totals: invoicesTotalNumber ?? 0 } : undefined,
                totalCount: invoicesTotalNumber || 0,
                reduceTotals: () => Promise.resolve({ totals: invoicesTotalNumber ?? 0 }),
                getTotalValues: () => ([]),
              };
            },
          },
          goToItem: (item) => hasAnyOfPermissions(...CanNavigateToMakePaymentPermissions) && goToInvoice(+item.id),
          customColumnsClass: {
            desktop: 'app-table-payment-solo-invoices',
          },
          columns: [
            {
              title: t('Solo_InvoiceNoShort_NoDot'),
              getValue: (item: IInvoice) => item.id,
            },
            {
              title: t('IssueDate'),
              getValue: (item: IInvoice) => formatDate(item.invoiceDate, DateFormats.ShortDate),
            },
            {
              title: t('Total'),
              getValue: (item: IInvoice) => formatMoney(item.currencyCodeId, item.Totals.Subtotal),
            },
            {
              title: t('Type'),
              getValue: () => (Math.random() > 0.5 ? 'SOLO' : 'ENTRY'),
            },
            {
              title: t('Method'),
              getValue: (item) => getPaymentMethodLabel(item.InvoicePaymentMethodId),
              queryParamSetting: {
                type: 'filter',
                key: 'paidByMethod',
                values: [
                  [InvoicePaymentMethodFilterOptions.All, t('All')],
                  [InvoicePaymentMethodFilterOptions.Unknown, t('-')],
                  [InvoicePaymentMethodFilterOptions.Auto, t('Auto')],
                  [InvoicePaymentMethodFilterOptions.Invoice, t('Manual')],
                ],
              },
            },
            {
              title: t('Status'),
              RenderValueComponent: InvoiceStatusColumn,
              queryParamSetting: {
                type: 'filter',
                key: 'invoiceStatusIds',
                values: [
                  [InvoiceStatusFilterOptions.All, t('All')],
                  [InvoiceStatusFilterOptions.Unknown, t('-')],
                  [InvoiceStatusFilterOptions.Unpaid, t('InvoiceStatusDue')],
                  [InvoiceStatusFilterOptions.Pending, t('InvoiceStatusProcessing')],
                  [InvoiceStatusFilterOptions.Paid, t('InvoiceStatusPaid')],
                  [InvoiceStatusFilterOptions.Failed, t('InvoiceStatusFailed')],
                  [InvoiceStatusFilterOptions.Overdue, t('InvoiceStatusOverdue')],
                ],
              },
            },
          ],
        }}
      />
    </>
  );
}
