import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { ReactComponent as ChangeDevicesIcon } from '../../../assets/icons/remove_devices_confirmation.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function RemoveDevicesConfirmationPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="Solo_RemoveDevices"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <ChangeDevicesIcon className="app-w-164 app-h-163" />
          <span className="app-popup-info">{t('Solo_AreYouSureWishRemoveTheseDevices')}</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Solo_NoGoBack')}</button>
          <button type="button" onClick={confirm} className="app-primary-button app-popup-right-button">{t('Yes')}</button>
        </>
      )}
    />
  );
}
