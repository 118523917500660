import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import { CanNavigateToSitesPermissions } from '../../../shared/appUIFramework/appPermisions/permissionsGroups';
import { useAppPermissions } from '../../../shared/appUIFramework/appPermisions/useAppPermissions';
import AppContentToolbar from '../../../shared/appUIFramework/components/AppContentToolbar';

export default function SystemTypesTabsHeader({ activeTab }: { activeTab: 'entry' | 'solo'}) {
  const { t } = useTranslation();

  const { hasAnyOfPermissions } = useAppPermissions();
  const tabs = [];

  if (hasAnyOfPermissions(...CanNavigateToSitesPermissions)) {
    tabs.push({ title: t('EntrySites'), key: 'entry', to: '/systems/entry' });
    tabs.push({ title: t('SoloSystemsFull'), key: 'solo', to: '/systems/solo' });
  }

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">
          { t('SoloSystems') }
        </span>
      </AppContentToolbar>
      <div className="app-tabs">
        { tabs.map(({ title, key, to }) => (
          <Link
            className={`app-tab ${key === activeTab ? 'app-tab-active' : ''}`}
            key={key}
            to={to}
          >
            { title }
          </Link>
        ))}
      </div>
    </>
  );
}
