import { useTranslation } from 'react-i18next';
import { useRef, useEffect, ReactNode } from 'react';
import { useLocation } from 'wouter';
import SystemTabsHeader from './components/SystemTabsHeader';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { IAccountCharges, useCustomerPricing } from '../../shared/appUIFramework/appBackend/useAccountCharges';
import './SystemTabsCosts.scss';
import { AccountCharges, BorderWidth, Size } from '../../shared/appUIFramework/components/AppAccountChargers';
import styles from '../payment/PaymentOverview.module.scss';
import AppTablePagination from '../../shared/appUIFramework/components/AppTablePagination/AppTablePagination';
import { getTotalPages } from '../../shared/appUIFramework/dataTransformers/getTotalPages';
import {
  useSiteCostsHistoryInfinite,
  useSiteCostsHistoryTotalNumber,
} from '../../shared/appUIFramework/appBackend/useSiteCostHistory';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { CurrencyCodeString, getTotalUnitsPrice } from '../../shared/appUIFramework/appBackend/useInvoices';
import { roundAsCurrencyOnUI } from '../../shared/jsUtils/roundToDecimals';
import { ISiteInfo } from './models/ISiteInfo';
import { useSiteInfo } from '../../shared/appUIFramework/appBackend/useSiteInfo';
import { PAGE_SIZE } from '../../shared/appUIFramework/hooks/useInfiniteWithPagination';
import { isScrollVisible } from '../../styles';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';
import { useFormatNumber } from '../../shared/appUIFramework/formatters/formatNumber';
import { ReactComponent as AllDevicesIcon } from '../../assets/icons/S Devices Icon.svg';
import { ReactComponent as ActiveDevicesIcon } from '../../assets/icons/active_devices.svg';
import { useFeatureFlags } from '../../shared/appUIFramework/featureFlags';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import AddDevicesPopup, { IAddDevicesForm } from './components/AddDevicesPopup';
import RemoveDevicesPopup, { IRemoveDevicesForm } from './components/RemoveDevicesPopup';
import AddDevicesConfirmationPopup from './components/AddDevicesConfirmationPopup';
import RemoveDevicesConfirmationPopup from './components/RemoveDevicesConfirmationPopup';

function getMonth(idx: number): string {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx);

  const locale = 'en-us';
  return objDate.toLocaleString(locale, { month: 'long' });
}

function getChargesForSiteCosts(site: ISiteInfo): IAccountCharges {
  const { costs } = useCustomerPricing();
  return {
    growth: 0,
    monthlyCharge: getTotalUnitsPrice(roundAsCurrencyOnUI(costs?.value ?? 0), site?.registeredAppUsers),
    currencyCode: costs?.currency ?? CurrencyCodeString.GBP,
  };
}

function DevicesIcon({ title, icon, amount }: { title: string, icon: ReactNode, amount: number }) {
  const formatNumber = useFormatNumber();

  return (
    <div className="app-d-flex app-flex-column">
      <div className="app-font-19 app-mb-12">{title}</div>
      <div className="app-d-flex app-gap-8 app-align-items-center app-justify-content-center">
        <div>{icon}</div>
        <div className="app-color-primary-green app-font-30">{formatNumber(amount)}</div>
      </div>
    </div>
  );
}

export default function SystemTabsCostsSolo() {
  const [, params] = useEntryOrSoloSystemRoute('/systems/solo/:siteId/costs');
  const [, setLocation] = useLocation();
  const { soloUi } = useFeatureFlags();

  const { siteInfo } = useSiteInfo(params?.siteId || '');
  const charges = getChargesForSiteCosts(siteInfo!);
  const { t } = useTranslation();

  const historyHeaderRowRef = useRef<HTMLDivElement | null>(null);
  const historyRowsRef = useRef<HTMLDivElement | null>(null);
  const siteHistoryTotalNumber = useSiteCostsHistoryTotalNumber({
    billableUnitUniqueId: siteInfo?.id,
  });
  const totalPages = getTotalPages(PAGE_SIZE, siteHistoryTotalNumber);
  const {
    items: siteHistory, page, setPage, loading: siteHistoryLoading,
  } = useSiteCostsHistoryInfinite(siteInfo?.id, totalPages, {
    scrollRef: historyRowsRef,
  });

  const goToInvoice = (invoiceId: string) => {
    setLocation(`/payment/overview/${invoiceId}`);
  };

  const isLoading = siteHistoryLoading || !charges || !siteHistory;

  useEffect(() => {
    if (!historyRowsRef.current) {
      return;
    }

    if (isScrollVisible(historyRowsRef.current)) {
      historyRowsRef.current.style.paddingRight = '17px';
      historyHeaderRowRef.current!.style.paddingRight = '45px';
    }
  }, [isLoading, siteHistory]);

  const addDevices = async () => {
    const data = await showPopup<IAddDevicesForm>(<AddDevicesPopup numberOfCurrentDevices={9} />);
    if (data) {
      const shouldAdd = await showPopup(<AddDevicesConfirmationPopup />);
      // eslint-disable-next-line no-console
      console.log(shouldAdd);
    }
  };

  const removeDevices = async () => {
    const data = await showPopup<IRemoveDevicesForm>(<RemoveDevicesPopup numberOfCurrentDevices={9} numberOfActiveDevices={8} />);
    if (data) {
      const shouldRemove = await showPopup(<RemoveDevicesConfirmationPopup />);
      // eslint-disable-next-line no-console
      console.log(shouldRemove);
    }
  };

  return (
    <>
      <SystemTabsHeader activeTab="costs" solo />
      <div className="app-content">
        <div className="site-costs-header">
          <button onClick={removeDevices} type="button" className="app-primary-button">
            {t('Solo_RemoveDevices')}
          </button>
          <button onClick={addDevices} type="button" className="app-primary-button">
            {t('Solo_AddDevices')}
          </button>
        </div>
        <div className="site-costs-container app-d-flex app-h-100pcnt">
          <AppShowLoading showLoading={isLoading}>
            <div className="site-costs-left app-pb-33 app-flex-1">
              <div className="app-paper">
                <div className="app-paper-title app-mb-44">
                  {t('Solo_SystemCharges')}
                </div>
                <div className="app-d-flex app-align-items-center app-mb-44 app-justify-content-space-around">
                  <AccountCharges
                    accountCharges={charges!}
                    circleTopKey="Solo_SystemCharges"
                    circleBottomKey="Solo_PerMonth"
                    showGrowth={false}
                    size={soloUi ? Size.Smaller : undefined}
                    borderWidth={soloUi ? BorderWidth.Bold : undefined}
                  />
                  <DevicesIcon
                    icon={<AllDevicesIcon />}
                    title={t('Solo_Devices')}
                    amount={9}
                  />
                  <DevicesIcon
                    icon={<ActiveDevicesIcon />}
                    title={t('Solo_ActiveDevices')}
                    amount={8}
                  />
                </div>
              </div>
            </div>
            <div className="site-costs-right app-pb-33 app-flex-1">
              <div className={styles.invoiceContainer}>
                <div className={styles.invoiceHeader}>
                  <div>
                    {t('Solo_SystemHistory')}
                  </div>
                </div>
                <div className="app-table">
                  <div
                    ref={historyHeaderRowRef}
                    className="app-table-header-row costsTableHeader costs"
                  >
                    <div>
                      {t('ViewSiteCost_Month')}
                    </div>
                    <div>
                      {t('ViewSiteCost_Charges')}
                    </div>
                    <div>
                      {t('Solo_Devices')}
                    </div>
                    <div />
                  </div>
                  <div
                    ref={historyRowsRef}
                    className={`app-flex-vertical-scrollable ${styles.invoicesList}`}
                  >
                    {siteHistory && siteHistory.map((item) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
                      <div
                        className="app-table-content-row app-d-flex app-align-items-center app-justify-content-between costs"
                        key={item.id}
                        role="row"
                        onClick={() => goToInvoice(item.id)}
                      >
                        <div>
                          {getMonth(item.invoiceMonth)}
                          {' '}
                          {item.invoiceYear}
                        </div>
                        <div>{formatMoney(item.currencyCode, item.chargesAmount)}</div>
                        <div>{item.activeUsers}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.paginationContainer}>
                <div
                  className="app-w-50pcnt app-mt-26 app-d-flex app-align-items-center app-justify-content-center"
                >
                  <AppTablePagination
                    activePage={page}
                    totalPages={totalPages}
                    onPageSelect={setPage}
                  />
                </div>
              </div>
            </div>
          </AppShowLoading>
        </div>
      </div>
    </>
  );
}
