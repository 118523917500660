import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CollapseIcon } from '../../assets/icons/Button-Control icons/Collaspe icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as TickValidIcon } from '../../assets/icons/Tick Valid icon.svg';
import PadlockIcon from '../../assets/icons/Padlock icon.svg';
import {
  IInvoice,
  InvoiceStatus,
  useInvoices,
  useInvoiceStatusOptions,
} from '../../shared/appUIFramework/appBackend/useInvoices';
import { IAutoPaymentInfo, useAutoPaymentInfo } from '../../shared/appUIFramework/appBackend/useAutoPaymentInfo';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import AppTooltip, { AppTooltipPlacement } from '../../shared/appUIFramework/components/AppTooltip';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import DisableAutomaticPaymentsConfirmationPopup from './components/DisableAutomaticPaymentsConfirmationPopup';
import { getInvoiceStatusColorClassName } from './invoice.helper';
import styles from './PaymentPayments.module.scss';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { Pay } from '../../shared/backend/auth/claims';
import { isManualPaymentAllowed, useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';
import DisableAutomaticPaymentsSuccessPopup from './components/DisableAutomaticPaymentsSuccessPopup';
import StripePopup, { StripeVariant } from './components/StripePopup';
import AppSwitch from '../../shared/appUIFramework/components/AppSwitch';
import PaymentHeaderSolo from './components/PaymentHeaderSolo';

function FormattedInvoice({ invoice }: { invoice: IInvoice }) {
  const { getOptionLabel } = useInvoiceStatusOptions();
  const formatDate = useFormatDate();
  return !invoice ? <></> : (
    <div className={styles.formattedInvoice}>
      <div>{invoice.invoiceId}</div>
      <div className={styles.hideOnSmall}>{formatDate(invoice.invoiceDate)}</div>
      <div>{formatMoney(invoice.currencyCodeId, invoice.Totals.Total)}</div>
      <div
        className={`app-uppercase app-weight-600 ${styles.hideOnSmall} ${getInvoiceStatusColorClassName(invoice.invoiceStatusId)}`}
      >
        {getOptionLabel(invoice.invoiceStatusId)}
      </div>
    </div>
  );
}

async function setNewPaymentDetails() {
  const hasSet = await showPopup(<StripePopup stripeVariant={StripeVariant.SetupAutopayments} />);
  return hasSet;
}

function AutoPaymentsInfo({ paymentsInfo }: { paymentsInfo?: IAutoPaymentInfo }) {
  const { t } = useTranslation();
  const { updatePaymentInfo } = useAutoPaymentInfo();

  const changeCard = async () => {
    const hasSet = await setNewPaymentDetails();
    if (hasSet) {
      await updatePaymentInfo();
    }
  };

  const buttonTextKey = paymentsInfo?.last4Digits ? 'ChangeCard' : 'UpdateCard';

  return (
    <div className="app-d-flex app-justify-content-between app-align-items-center app-max-w-716 app-mt-22">
      <div className="app-font-20  app-pl-20">
        <span className="app-mr-9">{paymentsInfo?.cardType || ''}</span>
        <span className="app-mr-9">
          ****&nbsp;
          {paymentsInfo?.last4Digits || ''}
        </span>
        <span>
          {paymentsInfo && <TickValidIcon />}
        </span>
      </div>
      <div>
        <button type="button" onClick={changeCard} className="app-primary-button">
          {t(buttonTextKey)}
        </button>
      </div>
    </div>
  );
}

export default function PaymentPaymentsSolo() {
  const { t } = useTranslation();
  const { paymentsInfo, updatePaymentInfo } = useAutoPaymentInfo();
  const [invoice, setInvoice] = useState<IInvoice | undefined>();
  const { invoices, mutate: mutateInvoices } = useInvoices({
    invoiceStatusIds: [InvoiceStatus.Unpaid, InvoiceStatus.Overdue, InvoiceStatus.Failed],
  });

  const makeOneTimePayment = async () => {
    if (invoice?.invoiceId) {
      const isSuccess = await Pay(invoice?.invoiceId);
      if (isSuccess) {
        setInvoice(undefined);
        await mutateInvoices(() => undefined);
      }
    }
  };

  const setupAutomaticPayments = async () => {
    const hasSet = await setNewPaymentDetails();
    if (hasSet) {
      await updatePaymentInfo();
    }
  };

  const resetAutomaticPayments = async () => {
    const isSuccess = await showPopup(<DisableAutomaticPaymentsConfirmationPopup />);
    if (isSuccess) {
      await updatePaymentInfo();
      await showPopup(<DisableAutomaticPaymentsSuccessPopup />);
    }
  };

  const autoPaymentEnabled = !!paymentsInfo?.autoPaymentEnabled;

  const { data: company } = useInternalAppsCompanyDetails();
  const isAutopaymentSwitchVisible = company && isManualPaymentAllowed(company);

  return (
    <>
      <PaymentHeaderSolo activeTab="payments" />
      <div className="app-content app-py-42 app-px-60">
        <AppShowLoading showLoading={!paymentsInfo}>
          <div className="app-flex-vertical">
            <div className={styles.notificationContainer}>
              <div className={styles.notificationContainerTitle}>
                <img src={PadlockIcon} alt="padlock icon" />
                <span className="app-ml-18">{t('SecurePayment')}</span>
              </div>
              <div className={styles.notificationContainerBody}>
                <b>
                  {t('PleaseNote')}
                  :&nbsp;
                </b>
                <span>{t('SecurePaymentText')}</span>
              </div>
            </div>
            <div className="app-d-flex app-flex-column app-gap-55">
              <div className="app-form-control">
                <div className="app-form-control-label">
                  {t('AutomaticPayments')}
                </div>
                <div className="app-d-flex app-align-items-center">
                  <div
                    className={`app-form-control-input ${styles.formControlShared} ${styles.autoPaymentMaxWidth}`}
                  >
                    <input
                      type="text"
                      value={t('AutomaticPayments')?.toString() || ''}
                      readOnly
                      className="app-form-input"
                    />
                    {isAutopaymentSwitchVisible && (
                      <div className="app-form-input-postfix-switch">
                        <AppSwitch
                          checked={autoPaymentEnabled}
                          onChange={() => {
                            setInvoice(undefined);
                            if (autoPaymentEnabled) {
                              resetAutomaticPayments();
                            } else {
                              setupAutomaticPayments();
                            }
                          }}
                          handleDiameter={30}
                          height={20}
                          width={48}
                        />
                      </div>
                    )}
                  </div>
                  <AppTooltip
                    popupTitle={t('AutomaticPayments')}
                    className={`app-ml-28 ${styles.tooltip}`}
                    preferredPlacement={AppTooltipPlacement.Right}
                  >
                    <div
                      className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
                    >
                      {t('AutomaticPayments')}
                    </div>
                    <div className="app-mt-28">
                      {t('Tooltip_AutomaticPayments_Body_1')}
                    </div>
                    <div className="app-mt-28">
                      {t('Tooltip_AutomaticPayments_Body_2')}
                    </div>
                    <div className="app-mt-28 app-mb-26">
                      {t('Tooltip_AutomaticPayments_Body_3')}
                    </div>
                  </AppTooltip>
                </div>
                {company && (
                  (autoPaymentEnabled && paymentsInfo)
                  || !isAutopaymentSwitchVisible
                ) && <AutoPaymentsInfo paymentsInfo={paymentsInfo} />}
              </div>
              {company && isManualPaymentAllowed(company) && (
                <div className="app-form-control">
                  <div className="app-form-control-label">
                    {t('PayAnInvoice')}
                  </div>
                  <div className={`app-d-flex app-align-items-center ${styles.payAnInvoice}`}>
                    <div className={styles.payAnInvoiceInner}>
                      <div className={`${styles.formControlShared} ${styles.invoicePopupMenu}`}>
                        <AppPopupMenu
                          disabled={autoPaymentEnabled || !invoices || invoices?.length === 0}
                          options={invoices || []}
                          getOptionLabel={(option) => <FormattedInvoice invoice={option} />}
                          getOptionKey={(option) => option.invoiceId}
                          selectedOption={invoice}
                          onOptionSelected={(option) => setInvoice(option)}
                          render={(isHidden) => (
                            <div
                              className={`app-form-control-input ${styles.formControlShared} ${styles.invoiceInput}`}
                            >
                              <input type="text" readOnly disabled={autoPaymentEnabled} />
                              {!invoice && (
                                <span
                                  className={styles.invoicePlaceholder}
                                  aria-disabled={autoPaymentEnabled ? true : undefined}
                                >
                                  {t('SelectInvoice')}
                                </span>
                              )}
                              <FormattedInvoice invoice={invoice!} />
                              <div
                                className={styles.invoicePostfix}
                                aria-disabled={autoPaymentEnabled ? true : undefined}
                              >
                                {isHidden ? <ExpandIcon /> : <CollapseIcon />}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={makeOneTimePayment}
                        disabled={!invoice}
                        className="app-primary-button"
                      >
                        {t('PayInvoice')}
                      </button>
                    </div>
                    <AppTooltip
                      popupTitle={t('PayAnInvoice')}
                      className={`app-ml-28 ${styles.tooltip}`}
                      disabled={autoPaymentEnabled}
                      preferredPlacement={AppTooltipPlacement.Right}
                    >
                      <div
                        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
                      >
                        {t('PayAnInvoice')}
                      </div>
                      <div className="app-mt-28">
                        {t('Tooltip_PayInvoice_Body_1')}
                      </div>
                      <div className="app-mt-28">
                        {t('Tooltip_PayInvoice_Body_2')}
                      </div>
                      <div className="app-mt-28 app-mb-26">
                        {t('Tooltip_PayInvoice_Body_3')}
                      </div>
                    </AppTooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        </AppShowLoading>
      </div>
    </>
  );
}
