import { useTranslation } from 'react-i18next';
import { ReactComponent as ConnectSuccessIcon } from '../../../assets/icons/Modal icons/System connected icon.svg';
import { useEntryOrSoloSystemRoute } from '../../../shared/appShellComponents/AppRouteSwitch';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function ConnectDeviceSuccessPopup() {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/:siteId/hardware');
  const siteId = params?.siteId;
  const { siteInfo } = useSiteInfo(siteId);
  const close = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="SiteAdd_ConnectToSite"
      extraClass="app-popup-connect"
      onHeaderClose={close}
      popupBody={(
        <>
          <span className="app-pt-20">
            <ConnectSuccessIcon />
          </span>
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('SiteRegistrationDialog_Connected') }
          </span>
          <span className="app-font-20">{ `${t('SiteRegistrationDialog_SuccessfullyConnected')} ${siteInfo?.siteName}` }</span>
        </>
      )}
    />
  );
}
