import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import SystemTabsHeader from './components/SystemTabsHeader';
import AppTablePage from '../../shared/appUIFramework/components/AppTablePage';
import { IGridQueryParams } from './models/IGridQueryParams';
import './SystemTabsHardwareSolo.scss';
import { useFormatDate, DateFormats } from '../../shared/appUIFramework/formatters/formatDate';
import MoreOptionsIcon from '../../assets/icons/Button-Control icons/More options button.svg';
import { ReactComponent as Signal4Icon } from '../../assets/icons/signal 4 of 5.svg';
import AppSwitch from '../../shared/appUIFramework/components/AppSwitch';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';

interface ISystemsGridParams extends IGridQueryParams {
  nameSort?: SortStringOptions;
}

interface ISystemsTotals {
  total: number;
}

interface ISoloSystem {
  id: string;
  deviceName: string;
  serialNumber: string;
  lastCommunicated: string;
  firmware: string;
  lastSignal: number;
  avgSignal: number;
  active: string;
}

function useSoloSystems(): ISoloSystem[] {
  return [
    {
      active: 'true',
      avgSignal: 1,
      lastSignal: 2,
      firmware: 'V.12349873',
      lastCommunicated: new Date().toISOString(),
      serialNumber: '72940765',
      deviceName: 'Annex door 1',
      id: 'c6a427d5-f66d-42be-bfd2-a50b9814da33',
    },
  ] as ISoloSystem[];
}

function SignalCellComponent({ value }: { value: string }) {
  const className = value === '1' || value === '2'
    ? 'app-color-mid-signal app-font-18 app-weight-600'
    : 'app-color-mid-signal app-font-18 app-weight-600';

  return (
    <div className="app-d-flex app-gap-15 app-align-items-center">
      <div className={className}>
        {value === '1' && 'OK'}
        {value === '2' && 'OK'}
      </div>
      <div>
        <Signal4Icon />
      </div>
    </div>
  );
}

function LastSignallCell({ item }: { item: ISoloSystem }) {
  return SignalCellComponent({ value: item.lastSignal.toString() });
}

function AvgSignallCell({ item }: { item: ISoloSystem }) {
  return SignalCellComponent({ value: item.avgSignal.toString() });
}

function ActiveCellValue({ item }: { item: ISoloSystem }) {
  const [checked, setChecked] = useState(item.active === 'true');

  return (
    <div>
      <AppSwitch
        checked={checked}
        onChange={setChecked}
        width={80}
      />
    </div>
  );
}

type EmptyAction = () => void;

function ActiveCellTitle({ title }: { title: string }) {
  const { t } = useTranslation();

  const [optionLabels] = useState<Record<string, string>>({
    deactivate: t('Solo_DeactivateAllDoors'),
  });

  const [actions] = useState<Record<string, EmptyAction>>({
    // eslint-disable-next-line no-alert
    deactivate: () => alert('Deactivate all not implemented'),
  });

  const options = Object.keys(optionLabels);
  const getOptionLabel = (option: string) => optionLabels[option];

  return (
    <div className="app-d-flex app-align-items-center app-gap-8">
      <div>{title}</div>
      <AppPopupMenu
        options={options}
        getOptionLabel={getOptionLabel as any}
        onOptionSelected={(option) => actions[option]()}
        render={() => (
          <div
            className="app-d-flex app-fit-content"
          >
            <img
              src={MoreOptionsIcon}
              alt="More"
            />
          </div>
        )}
      />
    </div>
  );
}

export default function SystemTabsHardwareSolo() {
  // get deps
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  // get data
  const systems = useSoloSystems();

  return (
    <>
      <SystemTabsHeader solo activeTab="hardware" />
      <AppTablePage<ISoloSystem, ISystemsGridParams, ISystemsTotals>
        inTabsShell
        extraContentClass="app-solo-systems-page"
        searchText={t('Solo_SearchDevices')}
        table={{
          query: {
            useItems: () => systems,
            initialQueryParams: {
              search: '',
              nameSort: SortStringOptions.Ascending.toString() as any,
            },
            useTotals: () => ({
              totals: { total: systems.length },
              totalCount: systems.length,
              reduceTotals: () => Promise.resolve({ total: systems.length }),
              getTotalValues: ({ total }) => ([
                {
                  label: t('Solo_TotalDevices'),
                  value: total,
                },
              ]),
            }),
          },
          customColumnsClass: {
            desktop: 'app-system-tabs-solo-hardware',
          },
          columns: [
            {
              title: t('Solo_DeviceName'),
              queryParamSetting: {
                key: 'nameSort',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ') as string],
                  [SortStringOptions.Descending, t('TableSort_ZTOA') as string],
                ],
                type: 'sort',
              },
              getValue: (item) => item.deviceName,
            },
            {
              title: t('Solo_SerialNumber'),
              getValue: (item) => item.serialNumber,
            },
            {
              title: t('Solo_LastCommunicated'),
              getValue: (item) => formatDate(item.lastCommunicated, DateFormats.LongDateMobile),
            },
            {
              title: t('Solo_Firmware'),
              getValue: (item) => item.firmware,
            },
            {
              title: t('Solo_LastSignal'),
              getValue: (item) => item.lastSignal.toString(),
              RenderValueComponent: LastSignallCell,
            },
            {
              title: t('Solo_AvgSignal'),
              getValue: (item) => item.avgSignal.toString(),
              RenderValueComponent: AvgSignallCell,
            },
            {
              title: t('Solo_Active'),
              getValue: (item) => item.active,
              RenderValueComponent: ActiveCellValue,
              RenderTitleComponent: ActiveCellTitle,
            },
          ],
        }}
      />
    </>
  );
}
