export const NON_BREAKING_SPACE = '\u00a0';

export const EMAIL_PATTERN = /^((?!\.)[\w\-_.]*(\+{0,1})([\w-_.]*)[^.])(@[\w-]+)((\.\w+)*[^.\W])$/gi;
// eslint-disable-next-line max-len
export const NO_SPECIAL_CHARACTERS_PATTERN = /^[ a-zA-Z0-9-_\\.,'äÄÁáÀàÂâĂăÃãÅåĄąÆæĆćČčÇçĐđËëÉéÈèÊêĘęĞğÏïÍíÎîIıÌìŁłŃńÑñÖöŐőÓóÒòÔôÕõØøŒœŚśŠšŞşȘșẞßŢţȚțÜüÚúÙùŰűŸÿŹźŽžŻż]+$/igu;
export const PHONE_PATTERN = /\b\d+(?: \d+)*\b/i;
export const PHONE_MIN_LENGTH = 8;
export const PHONE_MAX_LENGTH = 15;
export const NAME_MAX_LENGTH = 50;
export const GROUP_MAX_LENGTH = 20;
export const ADDRESS_MAX_LENGTH = 100;
export const POSTCODE_MAX_LENGTH = 12;
export const MONITOR_ID_MIN_VALUE = 1;
export const MONITOR_ID_MAX_VALUE = 9998;
export const CALL_NAME_MAX_LENGTH = 20;
export const NUMBER_PATTERN = /^\d+$/;
export const SOLO_MIN_ADD_DEVICES = 1;
export const SOLO_MAX_ADD_DEVICES = 99;

export const SELECT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_TIME_MS = 800;
export const MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID = 6;
export const PAXTON_EMAIL_DOMAINS = [
  '@paxton-access.co.uk',
  '@paxton-access.com',
  '@paxton-gmbh.de',
  '@paxton.co.uk',
  '@paxtonaccess.ae',
  '@paxtonaccess.co.za',
  '@paxtonaccess.dk',
  '@paxtonaccess.fr',
  '@paxtonaccess.no',
  '@paxtonaccess.se',
  '@paxton-benelux.com',
];
export const CURRENCY_DECIMALS_AMOUNT = 2;
