import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import { ReactComponent as BackBreadcrumpComponent } from '../../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import { getEntryOrSoloRoute, useEntryOrSoloSystemRoute } from '../../../shared/appShellComponents/AppRouteSwitch';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import {
  CanNavigateToEditEntryUserPermissions,
  CanNavigateToHardwarePermissions, CanNavigateToSiteCostsPermissions, CanNavigateToSiteDetailsPermissions,
} from '../../../shared/appUIFramework/appPermisions/permissionsGroups';
import { useAppPermissions } from '../../../shared/appUIFramework/appPermisions/useAppPermissions';
import AppContentToolbar from '../../../shared/appUIFramework/components/AppContentToolbar';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { useFeatureFlags } from '../../../shared/appUIFramework/featureFlags';

export default function SystemTabsHeader({ activeTab, solo }: { activeTab: 'entry-users' | 'system-details' | 'hardware' | 'costs', solo?: boolean}) {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/:siteId/:tab', solo);
  const siteId = params?.siteId || '';
  const { siteInfo } = useSiteInfo(siteId);

  const { soloUi } = useFeatureFlags();

  const { hasAnyOfPermissions } = useAppPermissions();
  const tabs = [];

  if (hasAnyOfPermissions(...CanNavigateToEditEntryUserPermissions)) {
    const usersPart = solo ? 'users' : 'entry-users';
    tabs.push({ title: t('ViewSiteUsers_Title'), key: 'entry-users', to: getEntryOrSoloRoute(`/systems/${siteId}/${usersPart}`, soloUi, solo) });
  }

  if (!solo) {
    if (hasAnyOfPermissions(...CanNavigateToHardwarePermissions)) {
      tabs.push({ title: t('ViewSiteHardware_Title'), key: 'hardware', to: getEntryOrSoloRoute(`/systems/${siteId}/hardware`, soloUi, solo) });
    }

    if (hasAnyOfPermissions(...CanNavigateToSiteDetailsPermissions)) {
      tabs.push({ title: t('ViewSiteDetails_Title'), key: 'system-details', to: getEntryOrSoloRoute(`/systems/${siteId}/system-details`, soloUi, solo) });
    }

    if (hasAnyOfPermissions(...CanNavigateToSiteCostsPermissions)) {
      tabs.push({ title: t('ViewSiteCosts_Title'), key: 'costs', to: getEntryOrSoloRoute(`/systems/${siteId}/costs`, soloUi, solo) });
    }
  } else {
    if (hasAnyOfPermissions(...CanNavigateToHardwarePermissions)) {
      tabs.push({ title: t('Solo_Devices'), key: 'hardware', to: getEntryOrSoloRoute(`/systems/${siteId}/hardware`, soloUi, solo) });
    }

    if (hasAnyOfPermissions(...CanNavigateToSiteCostsPermissions)) {
      tabs.push({ title: t('ViewSiteCosts_Title'), key: 'costs', to: getEntryOrSoloRoute(`/systems/${siteId}/costs`, soloUi, solo) });
    }

    if (hasAnyOfPermissions(...CanNavigateToSiteDetailsPermissions)) {
      tabs.push({ title: t('Solo_SystemDetails'), key: 'system-details', to: getEntryOrSoloRoute(`/systems/${siteId}/system-details`, soloUi, solo) });
    }
  }

  return (
    <>
      <AppContentToolbar>
        <Link to={getEntryOrSoloRoute('/systems', soloUi, solo)}><BackBreadcrumpComponent className="app-mr-9 app-pointer" /></Link>
        <span className="app-toolbar-breadcrumb">
          {soloUi && solo && t('Solo_PaxtonSolo')}
          {soloUi && !solo && t('Solo_PaxtonEntry')}
        </span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackBreadcrumpComponent /></span>
        <span className="app-toolbar-breadcrumb">
          <AppShowLoading inline showLoading={siteInfo == null}>
            { siteInfo?.siteName }
          </AppShowLoading>
        </span>
      </AppContentToolbar>
      <div className="app-tabs">
        { tabs.map(({ title, key, to }) => (
          <Link
            className={`app-tab ${key === activeTab ? 'app-tab-active' : ''}`}
            key={key}
            to={to}
          >
            { title }
          </Link>
        ))}
      </div>
    </>
  );
}
