import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import { ReactComponent as CompanyIcon } from '../../assets/icons/system details small/System name icon.svg';
import { ReactComponent as AddressIcon } from '../../assets/icons/system details small/Address icon.svg';
import { ReactComponent as SystemNameIcon } from '../../assets/icons/system details small/Site Name V2.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/system details small/Mobile icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/system details small/Telephone icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/system details small/Email small.svg';
import { useSiteInfo } from '../../shared/appUIFramework/appBackend/useSiteInfo';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { CanEditSiteDetailsPermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { countryList } from '../../shared/appUIFramework/constants/Countries';
import { formatSystemPhone } from '../../shared/appUIFramework/formatters/formatSitePhone';
import SystemTabsHeader from './components/SystemTabsHeader';
import { formatSystemAddress } from '../../shared/appUIFramework/formatters/formatSiteAddress';
import './SystemTabsSystemDetails.scss';
import {
  AccountStatusUIState,
  applyFunctionalityLimitedStyles,
  useAccountInfo,
} from '../../shared/appUIFramework/appBackend/useAccountInfo';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';

function SystemDetailsPaper({ title, disabled, children }: { title: string, disabled: boolean, children: ReactNode }) {
  return (
    <div className="app-system-details-paper" aria-disabled={disabled}>
      <div className="app-system-details-paper-title">
        {title}
      </div>
      <div className="app-system-details-paper-content">
        {children}
      </div>
    </div>
  );
}

function SystemDetailsPaperItem({ icon, content }: { icon: ReactNode, content: ReactNode }) {
  return (
    <div className="app-system-details-paper-content-item">
      <div className="app-system-details-paper-content-item-icon">
        {icon}
      </div>
      <div className="app-system-details-paper-content-item-content">
        {content}
      </div>
    </div>
  );
}

export default function SystemTabsSystemDetails() {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/:siteId/system-details');
  const { siteInfo } = useSiteInfo(params?.siteId || '');
  const { accountInfo } = useAccountInfo();
  const isAccountFunctionalityLimited = accountInfo.accountStatus === AccountStatusUIState.Limited;

  return (
    <>
      <SystemTabsHeader activeTab="system-details" />
      <div className="app-content app-pt-20 app-pb-33 app-px-60">
        <AppShowLoading showLoading={!siteInfo}>
          <div className="app-text-align-right">
            <AppPermissionComponentGuard permissions={CanEditSiteDetailsPermissions}>
              {isAccountFunctionalityLimited
                ? (
                  <span className="app-primary-button disabled-green" {...applyFunctionalityLimitedStyles(true)}>
                    {t('SiteAdd_EditSystemDetails')}
                  </span>
                )
                : (
                  <Link
                    to={`/systems/${params?.siteId}`}
                    className="app-primary-button"
                  >
                    {t('SiteAdd_EditSystemDetails')}
                  </Link>
                )}
            </AppPermissionComponentGuard>
          </div>
          <div className="app-mb-46 app-system-details-papers">
            <SystemDetailsPaper disabled={isAccountFunctionalityLimited} title={t('ViewSiteDetails_Title')}>
              <SystemDetailsPaperItem
                icon={<CompanyIcon />}
                content={siteInfo?.siteName}
              />
              <SystemDetailsPaperItem
                icon={<AddressIcon />}
                content={(
                  <>
                    <div>{formatSystemAddress(siteInfo?.siteAddress)}</div>
                    <div>
                      {countryList.find((p) => p.countryCode === siteInfo?.siteAddress?.countryCode)?.name}
                    </div>
                  </>
                )}
              />
            </SystemDetailsPaper>
            <SystemDetailsPaper disabled={isAccountFunctionalityLimited} title={t('Form_ContactDetails')}>
              <SystemDetailsPaperItem
                icon={<SystemNameIcon />}
                content={siteInfo?.siteContact.details}
              />
              <SystemDetailsPaperItem
                icon={<EmailIcon />}
                content={siteInfo?.siteContact.email}
              />
              <SystemDetailsPaperItem
                icon={<PhoneIcon />}
                content={siteInfo?.siteContact.phoneNumber2}
              />
              <SystemDetailsPaperItem
                icon={<MobileIcon />}
                content={formatSystemPhone(siteInfo?.siteContact.diallingCode, siteInfo?.siteContact.phoneNumber)}
              />
            </SystemDetailsPaper>
          </div>
        </AppShowLoading>
      </div>
    </>
  );
}
