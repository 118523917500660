import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useLocation } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import { ReactComponent as NameIcon } from '../../assets/icons/Text field icons/Name Icon V2.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/Text field icons/Email  icon.svg';
import LockIcon from '../../assets/icons/solo lock icon.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/solo mobile icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/solo calendar icon.svg';
import { useEntryUser } from '../../shared/appUIFramework/appBackend/useEntryUser';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import { useEntryOrSoloSystemRoute } from '../../shared/appShellComponents/AppRouteSwitch';
import './SystemEntryUserSolo.scss';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';

function EntryUserSoloValue({ label, value, icon }: { label: string, value?: string, icon: ReactNode }) {
  return (
    <div
      className="app-form-control"
    >
      <div className="app-form-control-label app-form-control-label-with-prefix">
        <span>{label}</span>
      </div>
      <div className="app-form-control-input">
        <div className="app-form-control-prefix">
          {icon}
        </div>
        <input
          value={value ?? ''}
          readOnly
          disabled
          type="text"
          className="app-form-input"
        />
      </div>
    </div>
  );
}

export default function SystemEntryUserSolo() {
  // get dependencies
  const { t } = useTranslation();

  // read route params
  const [, params] = useEntryOrSoloSystemRoute('/systems/solo/:siteId/users/:entryUserId');
  const { siteId, entryUserId } = params!;

  // read data required to show page
  const { entryUser } = useEntryUser(siteId, entryUserId);

  // methods used on the page
  const [, setLocation] = useLocation();
  const goToSite = () => {
    setLocation(`/systems/solo/${siteId}/users`);
  };

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb app-hover-pointer">{t('Solo_PaxtonSolo')}</span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{t('Solo_UserRecord')}</span>
      </AppContentToolbar>
      <div className="app-content">
        <AppShowLoading showLoading={entryUser == null}>
          <form
            className="app-form app-d-flex-vertical-100 app-user-solo-form"
          >
            <div
              className="app-form-section-title"
            >
              {t('Solo_AppUserDetails')}
            </div>
            <div
              className="app-d-flex app-flex-vertical app-mobile-form app-form-container app-flex-1"
            >
              <div className="app-form-column app-form-column-left">
                <EntryUserSoloValue
                  value={`${entryUser?.firstName} ${entryUser?.lastName}`}
                  label={t('Solo_UserForm_Name')}
                  icon={<NameIcon />}
                />
                <EntryUserSoloValue
                  value={entryUser?.email}
                  label={t('Email')}
                  icon={<EmailIcon />}
                />
                <EntryUserSoloValue
                  value="Working hours"
                  label={t('AccessLevel')}
                  icon={<img src={LockIcon} alt="lock icon" />}
                />
                <EntryUserSoloValue
                  value="None"
                  label={t('Solo_AppPermissions')}
                  icon={<MobileIcon />}
                />
                <EntryUserSoloValue
                  label={t('Solo_ValidFrom')}
                  icon={<CalendarIcon />}
                />
                <EntryUserSoloValue
                  label={t('Solo_ValidUntil')}
                  icon={<CalendarIcon />}
                />
              </div>
            </div>
            <div className="app-d-flex app-justify-content-end app-form-bottom-action-bar">
              <button
                onClick={goToSite}
                className="app-secondary-button app-mr-9"
                type="button"
              >
                {t('Back')}
              </button>
            </div>
          </form>
        </AppShowLoading>
      </div>
    </>
  );
}
