import { useTranslation } from 'react-i18next';
import styles from './AppPricePerUser.module.scss';
import PricingIcon from '../../../assets/icons/paxton_solo_pricing.svg';
import { formatMoney } from '../formatters/formatMoney';
import { CurrencyCodeString } from '../appBackend/useInvoices';

export default function AppPricePerSoloSystem({ costs, currency }: { costs: number, currency: CurrencyCodeString }) {
  const { t } = useTranslation();

  return (
    <div className={styles.costsContainer}>
      <div className="app-font-30 app-weight-600 app-mb-10">{t('Solo_PaxtonSolo')}</div>
      <div className="app-position-relative">
        <img src={PricingIcon} alt="costs icon" />
      </div>
      <div>
        <div className={styles.costsTextAmount}>{formatMoney(currency, +costs)}</div>
        <div className={styles.costsText}>
          {t('Solo_PerSoloControllerPerMonth')}
        </div>
      </div>
    </div>
  );
}
