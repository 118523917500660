import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import { useAppPermissions } from '../../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../../shared/appUIFramework/appPermisions/userPermission';
import AppContentToolbar from '../../../shared/appUIFramework/components/AppContentToolbar';

export default function PaymentHeaderSolo({ activeTab }:{activeTab: 'overview' | 'payments' | 'terms' | 'invoices'}) {
  const { t } = useTranslation();

  const { hasAnyOfPermissions } = useAppPermissions();
  const tabs = [];

  if (hasAnyOfPermissions(UserPermission.CanViewPaymentOverview)) {
    tabs.push({ title: t('Overview'), key: 'overview', to: '/payment/solo/overview' });
  }

  if (hasAnyOfPermissions(UserPermission.CanMakePayments)) {
    tabs.push({ title: t('Invoices'), key: 'invoices', to: '/payment/solo/invoices' });
  }

  if (hasAnyOfPermissions(UserPermission.CanMakePayments)) {
    tabs.push({ title: t('Payments'), key: 'payments', to: '/payment/solo/payments' });
  }

  if (hasAnyOfPermissions(UserPermission.CanViewPaymentOverview)) {
    tabs.push({ title: t('TermsAndConditions'), key: 'terms', to: '/payment/solo/terms' });
  }

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">{ t('Navigation_Payment') }</span>
      </AppContentToolbar>
      <div className="app-tabs">
        { tabs.map(({ title, key, to }) => (
          <Link
            className={`app-tab ${key === activeTab ? 'app-tab-active' : ''}`}
            key={key}
            to={to}
          >
            { title }
          </Link>
        ))}
      </div>
    </>
  );
}
