import { useTranslation } from 'react-i18next';
import { ReactComponent as ConnectSuccessIcon } from '../../../assets/icons/Modal icons/System connected icon.svg';
import { useEntryOrSoloSystemRoute } from '../../../shared/appShellComponents/AppRouteSwitch';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function ConnectDeviceDoYouWishToAddFirstMobileUserPopup() {
  const { t } = useTranslation();
  const [, params] = useEntryOrSoloSystemRoute('/systems/:siteId/hardware');
  const siteId = params?.siteId;
  const { siteInfo } = useSiteInfo(siteId);
  const closeWithFalse = () => closePopup(false);
  const closeWithTrue = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="SiteAdd_ConnectToSite"
      extraClass="app-popup-connect"
      buttons={(
        <>
          <button
            type="button"
            onClick={closeWithFalse}
            className="app-secondary-button app-popup-left-button"
          >
            {t('Popup_NoDoItLater')}
          </button>
          <button
            type="button"
            onClick={closeWithTrue}
            className="app-primary-button app-popup-right-button"
          >
            {t('Yes')}
          </button>
        </>
)}
      popupBody={(
        <>
          <ConnectSuccessIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            {t('SiteRegistrationDialog_Connected')}
          </span>
          <span className="app-d-flex app-flex-column app-justify-content-center app-align-items-center app-gap-8">
            <span
              className="app-font-20"
            >
              {`${t('SiteRegistrationDialog_SuccessfullyConnected')} ${siteInfo?.siteName}`}
            </span>
            <span
              className="app-font-20"
            >
              {t('DoYouWishToAddYourFirstMobileUserToTheSite')}
            </span>
          </span>
        </>
            )}
    />
  );
}
