import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import './ConnectDevicePopup.scss';
import { NUMBER_PATTERN, SOLO_MIN_ADD_DEVICES } from '../../../shared/appUIFramework/constants/constants';
import { useFormatNumber } from '../../../shared/appUIFramework/formatters/formatNumber';
import { formatMoney } from '../../../shared/appUIFramework/formatters/formatMoney';
import { CurrencyCode } from '../../../shared/appUIFramework/appBackend/useInvoices';

export interface IRemoveDevicesForm {
  numberOfDevices: number;
}

export default function RemoveDevicesPopup({
  numberOfCurrentDevices,
  numberOfActiveDevices,
}: {
  numberOfCurrentDevices: number,
  numberOfActiveDevices: number
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
      isValid,
    },
    getValues,
  } = useForm<IRemoveDevicesForm>({
    mode: 'onChange',
    defaultValues: {
      numberOfDevices: undefined,
    } as Partial<IRemoveDevicesForm>,
  });

  const formatNumber = useFormatNumber();
  const newNumberOfDevices = isValid ? numberOfCurrentDevices - getValues().numberOfDevices : numberOfCurrentDevices;
  const newNumberOfDevicesFormatted = isValid ? formatNumber(newNumberOfDevices) : '-';
  const maxNumberOfDevicesToRemove = numberOfCurrentDevices - numberOfActiveDevices;

  const closeWithFalse = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="Solo_RemoveDevices"
      extraClass="app-remove-devices-popup"
      popupBody={(
        <>
          <div className={`app-popup-info app-font-18 app-pt-30 ${isSubmitting ? 'app-invisible' : ''}`}>
            {t('Solo_EnterNumberOfDevicesToRemove')}
          </div>
          <form onSubmit={handleSubmit(closePopup)} className="app-popup-form">
            <div className={`app-popup-form-controls ${isSubmitting ? 'app-invisible' : ''}`}>
              <div className="app-d-flex h-100pcnt app-gap-135">
                <div className="app-popup-form-control" aria-invalid={!!errors.numberOfDevices}>
                  <div className="app-popup-form-control-label app-no-padding">
                    <span className="app-font-18">
                      {t('Solo_NumberOfDevicesToRemove')}
                    </span>
                    <span className="app-text-secondary-red-color">&nbsp;*</span>
                  </div>
                  <div className="app-popup-form-control-input">
                    <input
                      {...register('numberOfDevices', {
                        min: SOLO_MIN_ADD_DEVICES,
                        max: maxNumberOfDevicesToRemove,
                        valueAsNumber: true,
                        validate: {
                          required: (value) => !!value,
                          pattern: (value) => !!value && !!value.toString().match(NUMBER_PATTERN),
                        },
                      })}
                      type="number"
                      pattern="[1-9]"
                      className="app-popup-form-input"
                    />
                  </div>
                  {errors.numberOfDevices?.type === 'required' && (
                    <span
                      className="app-popup-form-error"
                    >
                      {t('Form_FieldRequired')}
                    </span>
                  )}
                  {errors.numberOfDevices?.type === 'min' && (
                    <span
                      className="app-popup-form-error"
                    >
                      {t('Solo_MinValue', { value: SOLO_MIN_ADD_DEVICES })}
                    </span>
                  )}
                  {errors.numberOfDevices?.type === 'max' && (
                    <span
                      className="app-popup-form-error"
                    >
                      {t('Solo_MaxValue', { value: maxNumberOfDevicesToRemove })}
                    </span>
                  )}
                  {errors.numberOfDevices?.type === 'pattern' && (
                    <span
                      className="app-popup-form-error"
                    >
                      {t('Solo_ShouldBeAFullInteger')}
                    </span>
                  )}
                </div>
                <div className="app-popup-form-control app-flex-1">
                  <div className="app-popup-form-control-label app-no-padding">
                    <span className="app-font-18">{t('Solo_Breakdown')}</span>
                  </div>
                  <div className="app-paper app-paper-form">
                    <div className="app-d-flex app-flex-column app-w-100pcnt app-gap-15">
                      <div className="app-d-flex app-justify-content-space-between app-w-100pcnt">
                        <div className="app-font-16">
                          {t('Solo_NumberOfCurrentDevices')}
                        </div>
                        <div className="app-font-19 app-color-primary-green">
                          {numberOfCurrentDevices}
                        </div>
                      </div>
                      <div className="app-d-flex app-justify-content-space-between app-w-100pcnt">
                        <div className="app-font-16">
                          {t('Solo_NumberOfCurrentActiveDevices')}
                        </div>
                        <div className="app-font-19 app-color-primary-green">
                          {numberOfActiveDevices}
                        </div>
                      </div>
                      <div className="app-d-flex app-justify-content-space-between app-pb-16 app-border-bottom-grey-light">
                        <div>
                          {t('Solo_NewTotalNumberOfDevices')}
                        </div>
                        <div className="app-color-primary-green app-font-19">
                          {newNumberOfDevicesFormatted}
                        </div>
                      </div>
                      <div className="app-color-primary-black app-d-flex app-justify-content-space-between app-align-items-center app-font-19">
                        <div>
                          {t('Solo_TotalMonthlyCharges')}
                          :
                        </div>
                        <div className="app-color-primary-green app-font-26">
                          {formatMoney(CurrencyCode.GBP, newNumberOfDevices * 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="app-text-secondary-red-color app-font-16 app-mt-12">
                *
                {t('Solo_OnlyInactiveDevicesCanBeRemoved')}
                .
              </div>
            </div>
            <div className="app-popup-buttons">
              <button
                type="button"
                onClick={closeWithFalse}
                className={`app-secondary-button app-popup-left-button ${isSubmitting ? 'app-invisible' : ''}`}
              >
                {t('Form_Cancel')}
              </button>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className={`app-primary-button app-popup-right-button ${isSubmitting ? 'app-invisible' : ''}`}
              >
                {t('Continue')}
              </button>
            </div>
          </form>
        </>
      )}
    />
  );
}
