import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as PaymentEntryUsers } from '../../assets/icons/payment_entry_users.svg';
import { ReactComponent as PaymentSoloDevices } from '../../assets/icons/payment_solo_devices.svg';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { CanNavigateToSitesPermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { useAppPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AccountActivityBarchart, { BarColor } from '../home/components/AccountActivityBarchart';
import '../home/Home.scss';
import '../home/HomeSolo.overrides.scss';
import { AccountCharges, BorderColors } from '../../shared/appUIFramework/components/AppAccountChargers';
import { useAccountActivity } from '../../shared/appUIFramework/appBackend/useAccountActivity';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import { useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';
import { InstallerStatusTranslationKeys } from '../admin/AdminCompanyDetails';
import { DateFormats, useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { formatPercent } from '../../shared/appUIFramework/formatters/formatPercent';
import PaymentHeaderSolo from './components/PaymentHeaderSolo';
import { useEntryHomeAnalytics } from '../home/HomeSolo';
import { getListOfYearsFromDate } from './PaymentOverview';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { CurrencyCode, CurrencyCodeString } from '../../shared/appUIFramework/appBackend/useInvoices';
import { useCustomerPricing } from '../../shared/appUIFramework/appBackend/useAccountCharges';

function CostsRawItem({
  title,
  cost,
  rrp,
  icon,
  currencyCode,
}: {
  title: string,
  cost: number,
  rrp: number,
  icon: ReactNode,
  currencyCode: CurrencyCode | CurrencyCodeString
}) {
  const { t } = useTranslation();
  return (
    <div className="app-d-flex app-flex-column app-gap-20">
      <div className="app-font-16 app-uppercase app-weight-600">{title}</div>
      <div className="app-d-flex app-gap-50">
        <div className="app-w-50">{icon}</div>
        <div className="app-d-flex app-flex-column app-text-align-right">
          <div className="app-font-40 app-weight-600">{formatMoney(currencyCode, cost)}</div>
          <div className="app-font-13 app-color-primary-green">
            {t('RRP')}
            &nbsp;
            <span className="app-weight-600">{formatMoney(currencyCode, rrp)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function PaymentOverview() {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const analytics = useEntryHomeAnalytics();
  const [activityMetricType, setActivityMetricType] = useState<'sites' | 'users'>('sites');
  const activityMetricTypes = ['sites', 'users'];
  const activityMetricLabels: Record<string, ReactNode> = {
    sites: <span className="app-selected-secondary-blue">{t('Home_AccountActivity_Systems')}</span>,
    users: <span className="app-selected-secondary-orange">{t('ViewSiteUsers_Title')}</span>,
  };
  const company = useCompany();

  const companyStartUsageYear = new Date(company?.termsAndConditionsAcceptedAt ?? new Date().toString()).getFullYear();
  const currentYear = new Date().getFullYear().toString();
  const accountActivityPerYearOptions = getListOfYearsFromDate(companyStartUsageYear);

  const [year, setYear] = useState(currentYear);
  // noinspection MagicNumberJS
  const { activity } = useAccountActivity(year, activityMetricType);

  const [, setLocation] = useLocation();
  const { hasAnyOfPermissions } = useAppPermissions();
  const goToSystems = () => {
    if (hasAnyOfPermissions(...CanNavigateToSitesPermissions)) {
      setLocation('/systems');
    }
  };

  const { data: iaCompany } = useInternalAppsCompanyDetails();

  const [systemType, setSystemType] = useState<'entry' | 'solo'>('entry');
  const systemTypes = ['entry', 'solo'];
  const systemTypesLabels: Record<string, ReactNode> = {
    entry: <span className="app-selected-secondary-blue">{t('Solo_PaxtonEntry')}</span>,
    solo: <span className="app-selected-secondary-orange">{t('Solo_PaxtonSolo')}</span>,
  };

  const { costs: entryAppUsersCosts } = useCustomerPricing();

  if (!analytics || !activity || !iaCompany || !entryAppUsersCosts) {
    return (
      <>
        <AppContentToolbar>
          <span className="app-toolbar-breadcrumb">{t('Navigation_Home')}</span>
        </AppContentToolbar>
        <div className="app-content app-py-36 app-px-30">
          <AppShowLoading showLoading />
        </div>
      </>
    );
  }

  return (
    <>
      <PaymentHeaderSolo activeTab="overview" />
      <div className="app-content app-py-36 app-px-30">
        <div className="app-home-top-charts">
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewNextEstimatedBill]}>
            <div className="app-home-paper app-home-paper-next-bill">
              <div className="app-home-paper-title">
                {t('TotalAccountCharges')}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="app-home-estimated-next-bill app-cursor-pointer"
                onClick={() => {
                  setLocation('/payment/overview');
                }}
              >
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: analytics.estimatedNextBill,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  circleTopKey="Home_EstimatedNextBill_AccountCharges"
                  circleBottomKey="Home_EstimatedNextBill_A_Month"
                />
              </div>
              <div className="app-home-paper-footer">
                <span className="app-home-paper-footer-text">
                  {t('Home_EstimatedNextBill_BillDate')}
                  :&nbsp;
                </span>
                <span className="app-home-paper-footer-text app-home-paper-footer-text-green">
                  {formatDate(analytics.estimatedNextBillDate, DateFormats.MM_DD_YYYY)}
                </span>
              </div>
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalDevices]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('PaxtonEntry')}
              </div>
              <div className="app-d-flex app-align-items-center app-w-100pcnt app-justify-content-space-evenly app-align-items-center">
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: analytics.estimatedNextBill,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  borderColor={BorderColors.Orange}
                  circleTopKey=""
                  circleBottomKey="InDebit"
                />
              </div>
              <div className="app-home-paper-footer" />
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalSites]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('PaxtonSolo')}
              </div>
              <div className="app-d-flex app-align-items-center app-w-100pcnt app-justify-content-space-evenly app-align-items-center">
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: 2000.50,
                    growth: 0.12,
                  }}
                  borderColor={BorderColors.Blue}
                  circleTopKey=""
                  circleBottomKey="InDebit"
                />
              </div>
              <div className="app-home-paper-footer" />
            </div>
          </AppPermissionComponentGuard>
        </div>
        <div className="app-payment-overview-bottom-grid">
          <div className="app-home-paper">
            <div className="app-home-paper-title">{t('ViewSiteCosts_Title')}</div>
            <div className="app-d-flex app-flex-column app-gap-30">
              <CostsRawItem
                currencyCode={entryAppUsersCosts.currency}
                title={t('Home_TotalDevices_EntryAppUsers')}
                icon={<PaymentEntryUsers />}
                rrp={entryAppUsersCosts.rrp}
                cost={entryAppUsersCosts.value}
              />
              <CostsRawItem
                currencyCode={CurrencyCode.GBP}
                title={t('Solo_SoloDevices')}
                icon={<PaymentSoloDevices />}
                rrp={8.88}
                cost={10}
              />
            </div>
            <div className="app-d-flex app-flex-column app-align-items-end app-w-100pcnt app-mt-22">
              <span className="app-uppercase app-font-16 app-weight-600">{t('Solo_PerProduct_PerMonth')}</span>
              <span className="app-uppercase app-font-12 app-color-primary-green">
                <b>{formatPercent(iaCompany.loyaltyDiscount * 100)}</b>
                &nbsp;
                <span>{t(InstallerStatusTranslationKeys[iaCompany.installerLoyaltyTier])}</span>
                &nbsp;
                <span>{t('Discount')}</span>
              </span>
            </div>
          </div>
          <div className="app-grid-2-cols-gap-30 app-h-100pcnt">
            <div className="app-home-paper app-home-paper-no-paddings">
              <div className="app-home-paper-activity-title app-d-flex app-justify-content-between app-align-items-center">
                <div className="app-home-paper-activity-title-text">
                  {t('Home_AccountActivity')}
                </div>
                <div className="app-d-flex app-justify-content-between">
                  <AppPopupMenu
                    options={systemTypes}
                    selectedOption={systemType}
                    getOptionLabel={(option) => systemTypesLabels[option]}
                    onOptionSelected={(option) => {
                      setSystemType(option);
                    }}
                    render={() => (
                      <div
                        className="app-home-paper-activity-bar-select app-mr-20 app-d-flex app-align-items-center"
                      >
                        <span className="app-mr-9 app-whitespace-no-wrap">{systemTypesLabels[systemType]}</span>
                        <ExpandIcon />
                      </div>
                    )}
                  />
                  <AppPopupMenu
                    options={activityMetricTypes}
                    selectedOption={activityMetricType}
                    getOptionLabel={(option) => activityMetricLabels[option]}
                    onOptionSelected={(option) => {
                      setActivityMetricType(option);
                    }}
                    render={() => (
                      <div
                        className="app-home-paper-activity-bar-select app-mr-20 app-d-flex app-align-items-center"
                      >
                        <span className="app-mr-9 app-whitespace-no-wrap">{activityMetricLabels[activityMetricType]}</span>
                        <ExpandIcon />
                      </div>
                    )}
                  />
                  <AppPopupMenu
                    options={accountActivityPerYearOptions}
                    getOptionLabel={(option) => (
                      <span className={activityMetricType === 'sites'
                        ? 'app-selected-secondary-blue'
                        : 'app-selected-secondary-orange'}
                      >
                        {option}
                      </span>
                    )}
                    selectedOption={year}
                    onOptionSelected={(option) => setYear(option)}
                    render={() => (
                      <div
                        className="app-home-paper-activity-bar-select app-d-flex app-align-items-center"
                      >
                        <span className="app-mr-9">{year}</span>
                        <ExpandIcon />
                      </div>
                    )}
                  />
                </div>
              </div>
              <AccountActivityBarchart
                title={activityMetricType === 'sites' ? t('Home_AccountActivity_Systems') : t('ViewSiteUsers_Title')}
                barColor={activityMetricType === 'sites' ? BarColor.Blue : BarColor.Orange}
                data={activity}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
