import { useTranslation } from 'react-i18next';
import styles from './AppPricePerUser.module.scss';
import PricingIcon from '../../../assets/icons/Pricing icon.svg';
import { formatMoney } from '../formatters/formatMoney';
import { CurrencyCodeString } from '../appBackend/useInvoices';
import { useFeatureFlags } from '../featureFlags';

export default function AppPricePerUser({ costs, currency }: { costs: number, currency: CurrencyCodeString }) {
  const { t } = useTranslation();
  const { soloUi } = useFeatureFlags();

  return (
    <div className={styles.costsContainer}>
      {soloUi && <div className="app-font-30 app-weight-600 app-mb-10">{t('Solo_Entry')}</div>}
      <div className="app-position-relative">
        <img src={PricingIcon} alt="costs icon" />
      </div>
      <div>
        <div className={styles.costsTextAmount}>{formatMoney(currency, +costs)}</div>
        <div className={styles.costsText}>
          {t('PerAppUser')}
        </div>
      </div>
    </div>
  );
}
