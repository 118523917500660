import { SyntheticEvent } from 'react';
import { Noop } from 'react-hook-form';
import Switch from 'react-switch';

export default function AppSwitch({
  checked,
  disabled,
  onChange,
  onBlur,
  width = 80,
  height = 44,
  handleDiameter = 24,
}: {
  checked: boolean,
  disabled?: boolean,
  onChange?: (
    checked: boolean,
    event: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void,
  onBlur?: Noop,
  width?: number,
  height?: number,
  handleDiameter?: number,
}) {
  return (
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={onChange ?? (() => {})}
      onBlurCapture={onBlur}
      onColor="#56aa1c"
      offColor="#ce171d"
      onHandleColor="#ffffff"
      handleDiameter={handleDiameter}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={height}
      width={width}
      className="lkk-switch"
      id="material-switch"
    />
  );
}
